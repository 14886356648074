import { React } from "react";
import { Link } from "react-router-dom";
import "./Projekty.css"
import ImageGallery from 'react-image-gallery';

const images1 = [
    {
        original: 'https://i.imgur.com/cjivu62.jpg',
        thumbnail: 'https://i.imgur.com/cjivu62.jpg',
    },
    {
        original: 'https://i.imgur.com/BuA16GH.jpg',
        thumbnail: 'https://i.imgur.com/BuA16GH.jpg',
    },
    {
        original: 'https://i.imgur.com/z03qWlx.jpg',
        thumbnail: 'https://i.imgur.com/z03qWlx.jpg',
    },
    {
        original: 'https://i.imgur.com/apxg2zV.jpg',
        thumbnail: 'https://i.imgur.com/apxg2zV.jpg',
    },
    {
        original: 'https://i.imgur.com/WXRCahh.jpg',
        thumbnail: 'https://i.imgur.com/WXRCahh.jpg',
    },
    {
        original: 'https://i.imgur.com/sYkmKGF.jpg',
        thumbnail: 'https://i.imgur.com/sYkmKGF.jpg',
    },
    {
        original: 'https://i.imgur.com/h5B3D6z.jpg',
        thumbnail: 'https://i.imgur.com/h5B3D6z.jpg',
    },
    {
        original: 'https://i.imgur.com/RnFy3ga.jpg',
        thumbnail: 'https://i.imgur.com/RnFy3ga.jpg',
    },
    {
        original: 'https://i.imgur.com/5Jc83CE.jpg',
        thumbnail: 'https://i.imgur.com/5Jc83CE.jpg',
    },
    {
        original: 'https://i.imgur.com/8K6qzSi.jpg',
        thumbnail: 'https://i.imgur.com/8K6qzSi.jpg',
    },
    {
        original: 'https://i.imgur.com/9Q9raTg.jpg',
        thumbnail: 'https://i.imgur.com/9Q9raTg.jpg',
    },
    {
        original: 'https://i.imgur.com/f8P04Uv.jpg',
        thumbnail: 'https://i.imgur.com/f8P04Uv.jpg',
    },
    {
        original: 'https://i.imgur.com/U1E7xRy.jpg',
        thumbnail: 'https://i.imgur.com/U1E7xRy.jpg',
    },
    {
        original: 'https://i.imgur.com/DCkfN4y.jpg',
        thumbnail: 'https://i.imgur.com/DCkfN4y.jpg',
    },
    {
        original: 'https://i.imgur.com/6tF19hJ.jpg',
        thumbnail: 'https://i.imgur.com/6tF19hJ.jpg',
    },
    {
        original: 'https://i.imgur.com/CSX22uD.jpg',
        thumbnail: 'https://i.imgur.com/CSX22uD.jpg',
    },
    {
        original: 'https://i.imgur.com/8oGnxmX.jpg',
        thumbnail: 'https://i.imgur.com/8oGnxmX.jpg',
    },
    {
        original: 'https://i.imgur.com/OoMzzG5.jpg',
        thumbnail: 'https://i.imgur.com/OoMzzG5.jpg',
    },
    {
        original: 'https://i.imgur.com/0fQNIXd.jpg',
        thumbnail: 'https://i.imgur.com/0fQNIXd.jpg',
    },
];

const images2 = [
    {
        original: 'https://i.imgur.com/Jx1y62L.jpg',
        thumbnail: 'https://i.imgur.com/Jx1y62L.jpg',
    },
    {
        original: 'https://i.imgur.com/DT2N7I1.jpg',
        thumbnail: 'https://i.imgur.com/DT2N7I1.jpg',
    },
    {
        original: 'https://i.imgur.com/JS3BVBv.jpg',
        thumbnail: 'https://i.imgur.com/JS3BVBv.jpg',
    },
    {
        original: 'https://i.imgur.com/O5x7dKW.jpg',
        thumbnail: 'https://i.imgur.com/O5x7dKW.jpg',
    },
    {
        original: 'https://i.imgur.com/RCpBdpO.jpg',
        thumbnail: 'https://i.imgur.com/RCpBdpO.jpg',
    },
    {
        original: 'https://i.imgur.com/qOiHnzK.jpg',
        thumbnail: 'https://i.imgur.com/qOiHnzK.jpg',
    },
    {
        original: 'https://i.imgur.com/YNC2LHX.jpg',
        thumbnail: 'https://i.imgur.com/YNC2LHX.jpg',
    },
    {
        original: 'https://i.imgur.com/zg7cXL3.jpg',
        thumbnail: 'https://i.imgur.com/zg7cXL3.jpg',
    },
    {
        original: 'https://i.imgur.com/iKaTO4W.jpg',
        thumbnail: 'https://i.imgur.com/iKaTO4W.jpg',
    },
    {
        original: 'https://i.imgur.com/FJw91ML.jpg',
        thumbnail: 'https://i.imgur.com/FJw91ML.jpg',
    },
    {
        original: 'https://i.imgur.com/eoEIlWS.jpg',
        thumbnail: 'https://i.imgur.com/eoEIlWS.jpg',
    },
    {
        original: 'https://i.imgur.com/J4F3Ufu.jpg',
        thumbnail: 'https://i.imgur.com/J4F3Ufu.jpg',
    },
    {
        original: 'https://i.imgur.com/pL9dF51.jpg',
        thumbnail: 'https://i.imgur.com/pL9dF51.jpg',
    },
    {
        original: 'https://i.imgur.com/sIK1Og0.jpg',
        thumbnail: 'https://i.imgur.com/sIK1Og0.jpg',
    },
    {
        original: 'https://i.imgur.com/c8X3eMr.jpg',
        thumbnail: 'https://i.imgur.com/c8X3eMr.jpg',
    },
    {
        original: 'https://i.imgur.com/NPz9M4H.jpg',
        thumbnail: 'https://i.imgur.com/NPz9M4H.jpg',
    },
];

const images3 = [
    {
        original: 'https://i.imgur.com/LXQhdlH.jpg',
        thumbnail: 'https://i.imgur.com/LXQhdlH.jpg',
    },
    {
        original: 'https://i.imgur.com/e3BiQTB.jpg',
        thumbnail: 'https://i.imgur.com/e3BiQTB.jpg',
    },
    {
        original: 'https://i.imgur.com/NdH85Cm.jpg',
        thumbnail: 'https://i.imgur.com/NdH85Cm.jpg',
    },
    {
        original: 'https://i.imgur.com/KHHzfmP.jpg',
        thumbnail: 'https://i.imgur.com/KHHzfmP.jpg',
    },
];

const images4 = [
    {
        original: 'https://i.imgur.com/CcphmSO.jpg',
        thumbnail: 'https://i.imgur.com/CcphmSO.jpg',
    },
    {
        original: 'https://i.imgur.com/iE6FLvn.jpg',
        thumbnail: 'https://i.imgur.com/iE6FLvn.jpg',
    },
    {
        original: 'https://i.imgur.com/GUkNlZt.jpg',
        thumbnail: 'https://i.imgur.com/GUkNlZt.jpg',
    },
    {
        original: 'https://i.imgur.com/0RYJvuf.jpg',
        thumbnail: 'https://i.imgur.com/0RYJvuf.jpg',
    },
    {
        original: 'https://i.imgur.com/MHJBbIm.jpg',
        thumbnail: 'https://i.imgur.com/MHJBbIm.jpg',
    },
    {
        original: 'https://i.imgur.com/e1O1yN9.jpg',
        thumbnail: 'https://i.imgur.com/e1O1yN9.jpg',
    },
    {
        original: 'https://i.imgur.com/w5EUAT9.jpg',
        thumbnail: 'https://i.imgur.com/w5EUAT9.jpg',
    },
    {
        original: 'https://i.imgur.com/xcadKwx.jpg',
        thumbnail: 'https://i.imgur.com/xcadKwx.jpg',
    },
];


const images5 = [
    {
        original: 'https://i.imgur.com/jDlK2rS.jpg',
        thumbnail: 'https://i.imgur.com/jDlK2rS.jpg',
    },
    {
        original: 'https://i.imgur.com/DbWXG3h.jpg',
        thumbnail: 'https://i.imgur.com/DbWXG3h.jpg',
    },
    {
        original: 'https://i.imgur.com/fEIkuEf.jpg',
        thumbnail: 'https://i.imgur.com/fEIkuEf.jpg',
    },
    {
        original: 'https://i.imgur.com/cIofSxI.jpg',
        thumbnail: 'https://i.imgur.com/cIofSxI.jpg',
    },
    {
        original: 'https://i.imgur.com/HbqPRSB.jpg',
        thumbnail: 'https://i.imgur.com/HbqPRSB.jpg',
    },
    {
        original: 'https://i.imgur.com/W25kziv.jpg',
        thumbnail: 'https://i.imgur.com/W25kziv.jpg',
    },
    {
        original: 'https://i.imgur.com/0sefhVU.jpg',
        thumbnail: 'https://i.imgur.com/0sefhVU.jpg',
    },
    {
        original: 'https://i.imgur.com/DulfCAx.jpg',
        thumbnail: 'https://i.imgur.com/DulfCAx.jpg',
    },
];

const Projekty2 = () => {
    return <div>
        <section id="section-1">
            <div className="pozemni">
                <div className="nadpis-pozemni">
                    <h2>Reference - Projekty</h2>
                </div>
                <div className="picovinky-veci">
                    <h3>Rodinný dům Praha Dubeč</h3>
                    <p>Technický dozor stavebníka</p>
                </div>
                <ImageGallery showPlayButton={false} showFullscreenButton={false} showBullets={true} items={images1} />
            </div>
        </section>
        <section id="section-2">
            <div className="pozemni">
                <div className="picovinky-veci">
                    <h3>SOŠ Lipí - zateplení a kompletní rekonstrukce objektů</h3>
                    <ul><li>Projektová dokumentace pro stavební povolení</li>
                        <li>Zajištění stavebního povolení</li>
                        <li>Autorský dozor</li>
                        <li>Technický dozor stavebníka </li></ul>
                    <p>Rekonstrukce stávajících objektů školy spočívající v demontáži vnějších plášťů <br /> a provedením nových sedvičových konstrukcí s okny, nové vytápění, elektroinstalace, hygienické prostory, multifunkční sál, učebny, komunikace, úpravy ploch pro parkování, sadové úpravy</p>
                </div>
                <ImageGallery showPlayButton={false} showFullscreenButton={false} showBullets={true} items={images2} />
            </div>
        </section>
        <section id="section-3">
            <div className="pozemni">
                <div className="picovinky-veci">
                    <h3>Novostavba 2xRD - Radonice u Prahy</h3>
                    <ul><li>Studie RD</li>
                        <li>Projektová dokumentace pro stavební povolení</li>
                        <li>Inženýrská činnost</li></ul>
                </div>
                <ImageGallery showPlayButton={false} showFullscreenButton={false} showBullets={true} items={images3} />
            </div>
        </section>
        <section id="section-4">
            <div className="pozemni">
                <div className="picovinky-veci">
                    <h3>Dolní Břežany - fontána</h3>
                    <ul><li>Projektová dokumentace provedení stavby</li></ul>
                </div>
                <ImageGallery showPlayButton={false} showFullscreenButton={false} showBullets={true} items={images4} />
            </div>
        </section>
        <section id="section-5-pro-2">
            <div className="pozemni">
                <div className="picovinky-veci">
                    <h3>Obec Libeř - cyklostezka</h3>
                    <ul><li>Projektová dokumentace pro územní a stavební řízení</li>
                        <li>Inženýrská činnost (ÚR+SP)</li>
                        <li>Rozpočet</li>
                        <li>Autorský dozor</li></ul>
                </div>
                <ImageGallery showPlayButton={false} showFullscreenButton={false} showBullets={true} items={images5} />
            </div>
        </section>
        <section id="strankovani">
            <div class="center">
                <div class="pagination-relas">
                    <Link to="../reference/projekty">1</Link>
                    <Link className="active">2</Link>
                    <Link to="../reference/projekty-3">3</Link>
                    <Link to="../reference/projekty-4">4</Link>
                    <Link to="../reference/projekty-5">5</Link>
                    <Link to="../reference/projekty-6">6</Link>
                </div>
            </div>
        </section>
    </div>
}

export default Projekty2