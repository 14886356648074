import { React } from "react";
import { Link } from "react-router-dom";
import "./Zamecnictvi.css"
import ImageGallery from 'react-image-gallery';

const images1 = [
    {
        original: 'https://i.imgur.com/03FKQEw.jpg',
        thumbnail: 'https://i.imgur.com/03FKQEw.jpg',
    },
    {
        original: 'https://i.imgur.com/7CxvjNJ.jpg',
        thumbnail: 'https://i.imgur.com/7CxvjNJ.jpg',
    },
];

const images2 = [
    {
        original: 'https://i.imgur.com/dyhn8J6.jpg',
        thumbnail: 'https://i.imgur.com/dyhn8J6.jpg',
    },
    {
        original: 'https://i.imgur.com/bdf6aD8.jpg',
        thumbnail: 'https://i.imgur.com/bdf6aD8.jpg',
    },
    {
        original: 'https://i.imgur.com/owSRGAm.jpg',
        thumbnail: 'https://i.imgur.com/owSRGAm.jpg',
    },
    {
        original: 'https://i.imgur.com/ipQdv1N.jpg',
        thumbnail: 'https://i.imgur.com/ipQdv1N.jpg',
    },
];

const images3 = [
    {
        original: 'https://i.imgur.com/pnS0pHN.jpg',
        thumbnail: 'https://i.imgur.com/pnS0pHN.jpg',
    },
    {
        original: 'https://i.imgur.com/QuNdBU7.jpg',
        thumbnail: 'https://i.imgur.com/QuNdBU7.jpg',
    },
    {
        original: 'https://i.imgur.com/Nb1kK3a.jpg',
        thumbnail: 'https://i.imgur.com/Nb1kK3a.jpg',
    },
    {
        original: 'https://i.imgur.com/MOSWVnr.jpg',
        thumbnail: 'https://i.imgur.com/MOSWVnr.jpg',
    },
];

const images4 = [
    {
        original: 'https://i.imgur.com/A1oMlvT.jpg',
        thumbnail: 'https://i.imgur.com/A1oMlvT.jpg',
    },
    {
        original: 'https://i.imgur.com/gIFkvap.jpg',
        thumbnail: 'https://i.imgur.com/gIFkvap.jpg',
    },
    {
        original: 'https://i.imgur.com/7AKXDAP.jpg',
        thumbnail: 'https://i.imgur.com/7AKXDAP.jpg',
    },
    {
        original: 'https://i.imgur.com/aV9RZBB.jpg',
        thumbnail: 'https://i.imgur.com/aV9RZBB.jpg',
    },
    {
        original: 'https://i.imgur.com/ypkdIEJ.jpg',
        thumbnail: 'https://i.imgur.com/ypkdIEJ.jpg',
    },
];


const images5 = [
    {
        original: 'https://i.imgur.com/OvRlybB.jpg',
        thumbnail: 'https://i.imgur.com/OvRlybB.jpg',
    },
    {
        original: 'https://i.imgur.com/7nDNzU2.jpg',
        thumbnail: 'https://i.imgur.com/7nDNzU2.jpg',
    },
    {
        original: 'https://i.imgur.com/f2uw40z.jpg',
        thumbnail: 'https://i.imgur.com/f2uw40z.jpg',
    },
    {
        original: 'https://i.imgur.com/8XRcspN.jpg',
        thumbnail: 'https://i.imgur.com/8XRcspN.jpg',
    },
    {
        original: 'https://i.imgur.com/SCjLv9a.jpg',
        thumbnail: 'https://i.imgur.com/SCjLv9a.jpg',
    },
];

const Zamecnictvi = () => {
    return <div>
        <section id="section-1">
            <div className="pozemni">
                <div className="nadpis-pozemni">
                    <h2>Reference - Zámečnictví</h2>
                </div>
                <div className="picovinky-veci">
                    <h3>Libeř zábradlí</h3>
                </div>
                <ImageGallery showPlayButton={false} showFullscreenButton={false} showBullets={true} items={images1} />
            </div>
        </section>
        <section id="section-2">
            <div className="pozemni">
                <div className="picovinky-veci">
                    <h3>Zámečnické prvky - Kompostárna Struhařov</h3>
                </div>
                <ImageGallery showPlayButton={false} showFullscreenButton={false} showBullets={true} items={images2} />
            </div>
        </section>
        <section id="section-3">
            <div className="pozemni">
                <div className="picovinky-veci">
                    <h3>Ochranná konstrukce v marketu Hornbach</h3>
                    <p>Dodávka a montáž ochranného rámu v prodejně Hornbach na Černém Mostě</p>
                </div>
                <ImageGallery showPlayButton={false} showFullscreenButton={false} showBullets={true} items={images3} />
            </div>
        </section>
        <section id="section-4">
            <div className="pozemni">
                <div className="picovinky-veci">
                    <h3>Oplocení sportoviště v Radotíně</h3>
                    <p>Dodávka a montáž žárově pozinkovaného oplocení víceúčelového hřiště v Radotíně</p>
                </div>
                <ImageGallery showPlayButton={false} showFullscreenButton={false} showBullets={true} items={images4} />
            </div>
        </section>
        <section id="section-5-pro-1">
            <div className="pozemni">
                <div className="picovinky-veci">
                    <h3>Oplocení a mobiliář MŠ Husníkova Praha 13</h3>
                    <p>Dodávka a montáž žárově pozinkovaného oplocení a laviček v areálu mateřské školy ve Stodůlkách</p>
                </div>
                <ImageGallery showPlayButton={false} showFullscreenButton={false} showBullets={true} items={images5} />
            </div>
        </section>
        <section id="strankovani">
            <div class="center">
                <div class="pagination-relas">
                    <Link className="active">1</Link>
                    <Link to="../reference/zamecnictvi-2">2</Link>
                </div>
            </div>
        </section>
    </div>
}

export default Zamecnictvi