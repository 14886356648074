const questions = [
    {
        id: 1,
        title: "Uvažuji o stavbě rodinného domu o půdorysu větším než 150 m2. Potřebuji stavební povolení?",
        info: "Na stavbu rodinného domu nově postačí jednodušší ohlášení stavby, pokud budou splněny podmínky uvedené v § 104 odst. 1 písm. a) stavebního zákona. Tzn., že rodinný dům bude mít jedno nadzemní podlaží do hloubky 3 m a nejvýše dvě nadzemní podlaží a podkroví. Současně musí stavebník doložit podklady podle § 105 a násl. stavebního zákona. Požadavek na dodržení limitního parametru 150 m2 celkové zastavěné plochy byl novelou vypuštěn."
    },
    {
        id: 2,
        title: "Vím, že novela umožňuje provést svépomocí výstavbu všech rodinných domů i drobných staveb, jako jsou bazény nebo skleníky. Znamená to, že úřadu už stavebník nemusí předkládat žádné dokumenty související s realizací stavby?",
        info: "Skutečnost, že se po 1. 1. 2018 nebudou některé stavby kolaudovat, nemění nic na tom, že stavebník a další osoby zúčastněné na výstavbě jsou nadále povinny plnit povinnosti vyplývající ze stavebního zákona a právních předpisů vydaných k jeho provedení. Stavebník je povinen provádět stavbu v souladu s rozhodnutím nebo jiným opatřením stavebního úřadu a s ověřenou projektovou dokumentací, což je základní povinností nejen stavebníka, ale též zhotovitele (stavebního podnikatele), stavbyvedoucího nebo osoby vykonávající stavební dozor (§ 160 a 153 stavebního zákona). Stavební zákon v § 152 dále stavebníkovi ukládá povinnost zajistit před započetím užívání stavby provedení a vyhodnocení zkoušek a měření předepsaných zvláštními právními předpisy (tlakové zkoušky, revizní zprávy atd.)."
    },
    {
        id: 3,
        title: "Lze oplocení do výšky 2 m vybudovat bez posouzení stavebním úřadem a souhlasu sousedů?",
        info: "Pokud oplocení splní parametry a podmínky uvedené v § 79 odst. 2 písm. f) stavebního zákona, to znamená, že je do výšky 2 m, nehraničí s veřejně přístupnými pozemními komunikacemi nebo s veřejným prostranstvím a nachází se v zastavěném území či v zastavitelné ploše, pak z hlediska posouzení stavebním úřadem nevyžaduje nic. Avšak dle ustanovení § 152 odst. 1 stavebního zákona o zahájení prací na stavbách osvobozených od povolení je stavebník povinen v dostatečném předstihu informovat osoby těmito pracemi přímo dotčené (tedy i sousedy). Táž podmínka platí i v případě rekonstrukce plotu."
    },
    {
        id: 4,
        title: "Co je to údržba stavby?",
        info: "Údržba stavby neboli udržovací práce se rozumějí práce, jimiž se zabezpečuje dobrý stavební stav tak, aby nedocházelo ke znehodnocení stavby a co nejvíce se prodloužila její uživatelnost. Tyto práce nepodléhají žádnému povolení, vyjma staveb, která jsou kulturní památkou. Zároveň je třeba podotknout, vlastník stavby má povinnost provádět údržbu objektu a stavební úřad má právo tyto práce nařídit k tíži vlastníka."
    },
    {
        id: 5,
        title: "Musí se žádat o povolení stavby, pokud chci zateplit plášť budovy?",
        info: "Dle stavebního zákona se zateplení pláště budovy vnímá jako stavební úprava změny dokončené stavby, tzn., že také nepodléhá žádnému povolení. Nicméně tato stavební úprava se většinou uplatní pouze u rodinných domů a menších objektů. U bytových staveb, staveb občanské vybavenosti, školských staveb si stavební úřad vyžádá minimálně stanoviska dotčených orgánů a v některých případech i stavební povolení. To samé platí i u staveb, které jsou kulturní památkou nebo se nacházejí v zónách dotčených památkovou péčí např. v Hlavním městě."
    },
    {
        id: 6,
        title: "Jak dlouho trvá platnost územního souhlasu u jednoduchých staveb a platnost ostatních povolení.",
        info: "Územní souhlas trvá 2 roky od vydání a jeho platnost nelze prodloužit. To samé platí u ohlášení stavebního záměru. Společné územní a stavební řízení má platnost 2 roky s možností prodloužení na 5 let. Stavební povolení trvá 2 roky a lze jeho platnost také prodloužit. Lhůtu, o kterou se toto povolení prodlužuje záleží na úvaze stavebního úřadu."
    },
    {
        id: 7,
        title: "Musí se k demolici staré stavby vyjadřovat stavební úřad?",
        info: "Ano, především u staveb na ohlášení a stavební povolení je vlastník stavby povinen ohlásit záměr odstranit stavbu. K žádosti je třeba i připojit dokumentaci odstranění stavby. Pokud je ve stavbě možnost výskytu nebezpečného azbestu nebo je kulturní památkou, musí se postupovat dle zvláštních předpisů."
    },
    {
        id: 8,
        title: "Chci si pořídit novou přípojku technické infrastruktury, jak mám postupovat?",
        info: "Veškeré přípojky (kanalizace, vodovod, plynovod, energetika…) jsou stavebním zákonem vnímány jako drobné stavby vyžadující územní souhlas. Přípojkou se myslí rozsah připojení od hlavního řadu (z ulice) po ukončení např. v revizní šachtě, vodoměrné šachtě, v osazené skříni v oplocení. Pokračování potrubí od těchto ukončení až do objektu již nepodléhá žádnému povolení, pouze musí být provedeno platných norem a technických podmínek. V případě tedy pořízení přípojky od hlavního řadu je třeba požádat o existenci sítí od příslušného správce této infrastruktury, dále zároveň požádat o technické podmínky připojení. Poté oslovit projektanta, který vyhotoví projekt pro územní souhlas, zajistit si potřebná vyjádření a stanoviska ostatních správců sítí a dotčených orgánů, podat poté vše na příslušný stavební úřad, který vydá územní souhlas. Až po vydání územního souhlasu lze provádět realizaci stavby."
    },
    {
        id: 9,
        title: "Proč tak dlouho trvá vyřízení jednotlivých řízení?",
        info: "Časové lhůty těchto řízení jsou velmi závislé na složitosti stavby, počtu účastníků řízení a jejich případných odvolání, správnosti projektové dokumentace a také pružnosti úředníků státní správy (stavební úřad). Proto níže uvedené termíny berte pouze v ideálním případě, a to tak, že máte podanou žádost se všemi náležitostmi a není stavba zatížena posouzením vlivu na životní prostředí, tzv. EIA. \n\n1. Územní rozhodnutí – v jednoduchých případech 60 dní + 25 dní nabytí právní moci, ve složitějších případech 90 dní + 25 dní nabytí právní moci \n\n2. Územní souhlas – do 30 dní \n\n3. Společný územní souhlas a ohlášení – do 30 dní \n\n4. Společné územní a stavební řízení - v jednoduchých případech 60 dní + 25 dní nabytí právní moci, ve složitějších případech 90 dní + 25 dní nabytí právní moci \n\n5. Ohlášení stavby – do 30 dní \n\n6. Stavební povolení - v jednoduchých případech 60 dní + 25 dní nabytí právní moci, ve složitějších případech 90 dní + 25 dní nabytí právní moci \n\n\n\nPozn: Novela stavebního zákona č. 225/2017 Sb."
    },
    {
        id: 10,
        title: "V čem se pro mě jako drobného stavebníka liší ohláška a stavební povolení?",
        info: "Ohláška i stavební povolení sice vyžadují téměř stejné množství dokumentů, u ohlášky ale úřady rozhodují rychleji. Zatímco na povolení se často čeká i několik měsíců, jednodušší ohlášení musí být vydáno do 30 dnů za předpokladu, že budou doloženy zákonem požadované doklady a podklady. Navíc se za něj platí nižší správní poplatky."
    },
    {
        id: 11,
        title: "Jaká pravidla nyní platí pro zahradní stavby?",
        info: "Například stavby skleníků a bazénů včetně souvisejícího technického zařízení na pozemcích rodinných domů a staveb pro rodinnou rekreaci s parametry uvedenými ve stavebním zákoně nevyžadují vydání územního rozhodnutí ani souhlasu a také nevyžadují vydání stavebního povolení nebo ohlášení. Jedná se o skleníky do 40 m2 zastavěné plochy, do 5 m výšky v odstupové vzdálenosti nejméně 2 m od hranice pozemku a bazény do 40 m2 zastavěné plochy v odstupové vzdálenosti nejméně 2 m od hranice pozemku. V případě nedodržení zákonem stanovených parametrů je třeba výše uvedené stavby územně posoudit (vydat územní souhlas nebo územní rozhodnutí)."
    },
]

export default questions