import { React } from "react";
import { Link } from "react-router-dom";
import "./Pozemni-stavby-4.css"
import ImageGallery from 'react-image-gallery';

const images1 = [
    {
        original: 'https://i.imgur.com/57K5iIr.jpg',
        thumbnail: 'https://i.imgur.com/57K5iIr.jpg',
    },
    {
        original: 'https://i.imgur.com/31uOSue.jpg',
        thumbnail: 'https://i.imgur.com/31uOSue.jpg',
    },
    {
        original: 'https://i.imgur.com/LSvscrO.jpg',
        thumbnail: 'https://i.imgur.com/LSvscrO.jpg',
    },
    {
        original: 'https://i.imgur.com/62u0I47.jpg',
        thumbnail: 'https://i.imgur.com/62u0I47.jpg',
    },
    {
        original: 'https://i.imgur.com/dDrReSa.jpg',
        thumbnail: 'https://i.imgur.com/dDrReSa.jpg',
    },
    {
        original: 'https://i.imgur.com/U5POshW.jpg',
        thumbnail: 'https://i.imgur.com/U5POshW.jpg',
    },
];

const images2 = [
    {
        original: 'https://i.imgur.com/OcBHANF.jpg',
        thumbnail: 'https://i.imgur.com/OcBHANF.jpg',
    },
    {
        original: 'https://i.imgur.com/Oauiu8s.jpg',
        thumbnail: 'https://i.imgur.com/Oauiu8s.jpg',
    },
];

const PozemniStavby2 = () => {
    return <div>
        <section id="section-1">
            <div className="pozemni">
                <div className="nadpis-pozemni">
                    <h2>Reference - Pozemní stavby</h2>
                </div>
                <div className="picovinky-veci">
                    <h3>Obytný soubor Věžická, Horní Počernice</h3>
                </div>
                <ImageGallery showPlayButton={false} showFullscreenButton={false} showBullets={true} items={images1} />
            </div>
        </section>
        <section id="section-2-4">
            <div className="pozemni">
                <div className="picovinky-veci">
                    <h3>Průmyslové budovy v areálu společnosti Unilever</h3>
                    <p>Výstavba průmyslových budov a infrastruktury v areálu společnosti Unilever. <br />
Jednalo se o budovy margarinky, kosmetiky a dalších administativních budov, v areálu došlo k výstavbě nové kanalizace a zpevněných ploch.</p>
                </div>
                <ImageGallery showPlayButton={false} showFullscreenButton={false} showBullets={true} items={images2} />
            </div>
        </section>
        <section id="strankovani">
            <div class="center">
                <div class="pagination-relas">
                    <Link to="../reference/pozemni-stavby">1</Link>
                    <Link to="../reference/pozemni-stavby-2">2</Link>
                    <Link to="../reference/pozemni-stavby-3">3</Link>
                    <Link className="active">4</Link>
                </div>
            </div>
        </section>
    </div>
}

export default PozemniStavby2