import { React } from "react";
import { Link } from "react-router-dom";
import "./InzenyrskeStavby.css"
import ImageGallery from 'react-image-gallery';

const images1 = [
    {
        original: 'https://i.imgur.com/8qLGAOC.jpg',
        thumbnail: 'https://i.imgur.com/8qLGAOC.jpg',
    },
    {
        original: 'https://i.imgur.com/MISeUCO.jpg',
        thumbnail: 'https://i.imgur.com/MISeUCO.jpg',
    },
    {
        original: 'https://i.imgur.com/y6ys3pF.jpg',
        thumbnail: 'https://i.imgur.com/y6ys3pF.jpg',
    },
    {
        original: 'https://i.imgur.com/qpP4vzl.jpg',
        thumbnail: 'https://i.imgur.com/qpP4vzl.jpg',
    },
    {
        original: 'https://i.imgur.com/zCzUdqs.jpg',
        thumbnail: 'https://i.imgur.com/zCzUdqs.jpg',
    },
];

const images2 = [
    {
        original: 'https://i.imgur.com/Nktyk1D.jpg',
        thumbnail: 'https://i.imgur.com/Nktyk1D.jpg',
    },
    {
        original: 'https://i.imgur.com/ppF08Bq.jpg',
        thumbnail: 'https://i.imgur.com/ppF08Bq.jpg',
    },
    {
        original: 'https://i.imgur.com/m2IS7nc.jpg',
        thumbnail: 'https://i.imgur.com/m2IS7nc.jpg',
    },
    {
        original: 'https://i.imgur.com/7uFQxZ4.jpg',
        thumbnail: 'https://i.imgur.com/7uFQxZ4.jpg',
    },
    {
        original: 'https://i.imgur.com/NASDHCu.jpg',
        thumbnail: 'https://i.imgur.com/NASDHCu.jpg',
    },
];

const InzenyrskeStavby5 = () => {
    return <div>
        <section id="section-1">
            <div className="pozemni">
                <div className="nadpis-pozemni">
                    <h2>Reference - Inženýrské stavby</h2>
                </div>
                <div className="picovinky-veci">
                    <h3>TV Kbely</h3>
                </div>
                <ImageGallery showPlayButton={false} showFullscreenButton={false} showBullets={true} items={images1} />
            </div>
        </section>
        <section id="section-2-inz-5">
            <div className="pozemni">
                <div className="picovinky-veci">
                    <h3>Řevnice-rekonstrukce koryta potoka</h3>
                </div>
                <ImageGallery showPlayButton={false} showFullscreenButton={false} showBullets={true} items={images2} />
            </div>
        </section>
        <section id="strankovani">
            <div class="center">
                <div class="pagination-relas">
                    <Link to="../reference/inzenyrske-stavby">1</Link>
                    <Link to="../reference/inzenyrske-stavby-2" >2</Link>
                    <Link to="../reference/inzenyrske-stavby-3">3</Link>
                    <Link to="../reference/inzenyrske-stavby-4">4</Link>
                    <Link className="active">5</Link>
                </div>
            </div>
        </section>
    </div>
}

export default InzenyrskeStavby5