import { Helmet } from "react-helmet";
import "./Kariera.css"

const Kariera = () => {

    return <div>
        <Helmet>
<title>Kariéra</title>
<meta name="title" content="EKIS - Kariéra"/>
<meta name="description" content=""/>
<meta property="og:type" content="website"/>
<meta property="og:url" content="http://ekis-as.cz/kariera"/>
<meta property="og:title" content="EKIS - Kariéra"/>
<meta property="og:description" content=""/>
<meta property="og:image" content=""/>
        </Helmet>
        <section id="kariera">
            <div className="kariera-items">
            <h2 className="kariera-h2">Volné pracovní pozice ve společnosti EKIS</h2>
            <ul>
                <li><strong>Pozice: Zedník, stavební dělník</strong></li>
                <li><strong>Nástup:</strong> Ihned, možné brigádně</li>
                <li><strong>Popis pozice:</strong> Práce při výstavbě komunikací, vč. obrubníků a zámkové dlažby.<br/>Možno i pro celé pracovní party</li>
                <li><strong>Co požadujeme:</strong> Praxi na stavbě, vyučení stavebního směru výhodou</li>
                <li><strong>Co nabízíme:</strong> Zázemí stabilní firmy, zajímavé finanční ohodnocení, příspěvek na stravování, ubytování</li>
            </ul>
            <a href="/kontakt" rel="noreferrer">Mám zájem o tuto pozici</a>
            </div>
            </section>
    </div>
}

export default Kariera