import Navbar from "./Navbar"
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Spolecnost from "./pages/Spolecnost";
import Home from "./pages/Home";
import Reference from "./pages/Referece";
import PozemniStavby from "./pages/relasek/pozemni/Pozemni-stavby";
import PozemniStavby2 from "./pages/relasek/pozemni/Pozemni-stavby-2";
import PozemniStavby3 from "./pages/relasek/pozemni/Pozemni-stavby-3";
import PozemniStavby4 from "./pages/relasek/pozemni/Pozemni-stavby-4";
import InzenyrskeStavby from "./pages/relasek/inzenyr/InzenyrskeStavby";
import Projekty from "./pages/relasek/projektos/Projekty";
import Poradenstvi from "./pages/Poradenstvi";
import Kariera from "./pages/Kariera";
import Kontakt from "./pages/Kontakt";
import Footer from "./Footer";
import ScrollToTop from "./components/ScrollToTop";
import Certifikaty from "./pages/Certifikaty";
import InzenyrskeStavby2 from "./pages/relasek/inzenyr/InzenyrskeStavby-2";
import InzenyrskeStavby3 from "./pages/relasek/inzenyr/InzenyrskeStavby-3";
import InzenyrskeStavby4 from "./pages/relasek/inzenyr/InzenyrskeStavby-4";
import InzenyrskeStavby5 from "./pages/relasek/inzenyr/InzenyrskeStavby-5";
import Projekty2 from "./pages/relasek/projektos/Projekty-2";
import Projekty3 from "./pages/relasek/projektos/Projekty-3";
import Projekty4 from "./pages/relasek/projektos/Projekty-4";
import Projekty5 from "./pages/relasek/projektos/Projekty-5";
import Projekty6 from "./pages/relasek/projektos/Projekty-6";
import Zamecnictvi from "./pages/relasek/zamek/Zamecnictvi";
import Zamecnictvi2 from "./pages/relasek/zamek/Zamecnictvi-2";
import InstTopPrace from "./pages/relasek/inst/InstTopPrace";
const App = () => {
  return <div>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Navbar />}>
          <Route exact path="/" element={<Home />} />
          <Route path="/spolecnost" element={<Spolecnost />} />
          <Route path="/certifikaty" element={<Certifikaty />} />
          <Route path="/reference" element={<Reference />} />
          <Route path="/reference/pozemni-stavby" element={<PozemniStavby />} />
          <Route path="/reference/pozemni-stavby-2" element={<PozemniStavby2 />} />
          <Route path="/reference/pozemni-stavby-3" element={<PozemniStavby3 />} />
          <Route path="/reference/pozemni-stavby-4" element={<PozemniStavby4 />} />
          <Route path="/reference/inzenyrske-stavby" element={<InzenyrskeStavby/>} />
          <Route path="/reference/inzenyrske-stavby-2" element={<InzenyrskeStavby2/>} />
          <Route path="/reference/inzenyrske-stavby-3" element={<InzenyrskeStavby3/>} />
          <Route path="/reference/inzenyrske-stavby-4" element={<InzenyrskeStavby4/>} />
          <Route path="/reference/inzenyrske-stavby-5" element={<InzenyrskeStavby5/>} />
          <Route path="/reference/projekty" element={<Projekty />} />
          <Route path="/reference/projekty-2" element={<Projekty2 />} />
          <Route path="/reference/projekty-3" element={<Projekty3 />} />
          <Route path="/reference/projekty-4" element={<Projekty4 />} />
          <Route path="/reference/projekty-5" element={<Projekty5 />} />
          <Route path="/reference/projekty-6" element={<Projekty6 />} />
          <Route path="/reference/zamecnictvi" element={<Zamecnictvi />} />
          <Route path="/reference/zamecnictvi-2" element={<Zamecnictvi2 />} />
          <Route path="/reference/instalaterske-a-top-prace" element={<InstTopPrace />} />
          <Route path="/poradenstvi" element={<Poradenstvi />} />
          <Route path="/kariera" element={<Kariera />} />
          <Route path="/kontakt" element={<Kontakt />} />
        </Route>
      </Routes>
      <ScrollToTop />
    </BrowserRouter>
    <Footer/>
  </div>
}

export default App