import { React } from "react";
import { Link } from "react-router-dom";
import "./Projekty.css"
import ImageGallery from 'react-image-gallery';

const images1 = [
    {
        original: 'https://i.imgur.com/yZEkXxo.jpg',
        thumbnail: 'https://i.imgur.com/yZEkXxo.jpg',
    },
    {
        original: 'https://i.imgur.com/nAIXjAD.jpg',
        thumbnail: 'https://i.imgur.com/nAIXjAD.jpg',
    },
    {
        original: 'https://i.imgur.com/zu1kzS9.jpg',
        thumbnail: 'https://i.imgur.com/zu1kzS9.jpg',
    },
    {
        original: 'https://i.imgur.com/RhEIwTU.jpg',
        thumbnail: 'https://i.imgur.com/RhEIwTU.jpg',
    },
];

const images2 = [
    {
        original: 'https://i.imgur.com/SsdnMla.jpg',
        thumbnail: 'https://i.imgur.com/SsdnMla.jpg',
    },
    {
        original: 'https://i.imgur.com/6NB5max.jpg',
        thumbnail: 'https://i.imgur.com/6NB5max.jpg',
    },
    {
        original: 'https://i.imgur.com/oLIfZCV.jpg',
        thumbnail: 'https://i.imgur.com/oLIfZCV.jpg',
    },
    {
        original: 'https://i.imgur.com/VfEgi7L.jpg',
        thumbnail: 'https://i.imgur.com/VfEgi7L.jpg',
    },
];

const images3 = [
    {
        original: 'https://i.imgur.com/S9TCypn.jpg',
        thumbnail: 'https://i.imgur.com/S9TCypn.jpg',
    },
    {
        original: 'https://i.imgur.com/3seKVsJ.jpg',
        thumbnail: 'https://i.imgur.com/3seKVsJ.jpg',
    },
    {
        original: 'https://i.imgur.com/UIokm94.jpg',
        thumbnail: 'https://i.imgur.com/UIokm94.jpg',
    },
    {
        original: 'https://i.imgur.com/P5WsuAl.jpg',
        thumbnail: 'https://i.imgur.com/P5WsuAl.jpg',
    },
];

const images4 = [
    {
        original: 'https://i.imgur.com/Veo0LAY.jpg',
        thumbnail: 'https://i.imgur.com/Veo0LAY.jpg',
    },
    {
        original: 'https://i.imgur.com/t0SKR3E.jpg',
        thumbnail: 'https://i.imgur.com/t0SKR3E.jpg',
    },
    {
        original: 'https://i.imgur.com/xA1ZGrF.jpg',
        thumbnail: 'https://i.imgur.com/xA1ZGrF.jpg',
    },
    {
        original: 'https://i.imgur.com/UkKUM7A.jpg',
        thumbnail: 'https://i.imgur.com/UkKUM7A.jpg',
    },
    {
        original: 'https://i.imgur.com/zVAiPu4.jpg',
        thumbnail: 'https://i.imgur.com/zVAiPu4.jpg',
    },
    {
        original: 'https://i.imgur.com/bgiOPJm.jpg',
        thumbnail: 'https://i.imgur.com/bgiOPJm.jpg',
    },
    {
        original: 'https://i.imgur.com/cUSGi0i.jpg',
        thumbnail: 'https://i.imgur.com/cUSGi0i.jpg',
    },
    {
        original: 'https://i.imgur.com/IbjUmBo.jpg',
        thumbnail: 'https://i.imgur.com/IbjUmBo.jpg',
    },
    {
        original: 'https://i.imgur.com/wj41wPe.jpg',
        thumbnail: 'https://i.imgur.com/wj41wPe.jpg',
    },
    {
        original: 'https://i.imgur.com/XgKOtQN.jpg',
        thumbnail: 'https://i.imgur.com/XgKOtQN.jpg',
    },
    {
        original: 'https://i.imgur.com/63NfFmY.jpg',
        thumbnail: 'https://i.imgur.com/63NfFmY.jpg',
    },
    {
        original: 'https://i.imgur.com/EZ6GIzu.jpg',
        thumbnail: 'https://i.imgur.com/EZ6GIzu.jpg',
    },
    {
        original: 'https://i.imgur.com/i0PxbQN.jpg',
        thumbnail: 'https://i.imgur.com/i0PxbQN.jpg',
    },
];


const images5 = [
    {
        original: 'https://i.imgur.com/QPnsdS9.jpg',
        thumbnail: 'https://i.imgur.com/QPnsdS9.jpg',
    },
    {
        original: 'https://i.imgur.com/DlvJyXq.jpg',
        thumbnail: 'https://i.imgur.com/DlvJyXq.jpg',
    },
    {
        original: 'https://i.imgur.com/xIJojQ1.jpg',
        thumbnail: 'https://i.imgur.com/xIJojQ1.jpg',
    },
    {
        original: 'https://i.imgur.com/ugEkPLb.jpg',
        thumbnail: 'https://i.imgur.com/ugEkPLb.jpg',
    },
];

const Projekty5 = () => {
    return <div>
        <section id="section-1-pro-5">
            <div className="pozemni">
                <div className="nadpis-pozemni">
                    <h2>Reference - Projekty</h2>
                </div>
                <div className="picovinky-veci">
                    <h3>Rodinný dům v Horních Počernicích</h3>
                    <ul><li>Realizační projekt, zajištění stavebního povolení a autorský dozor</li>
                        <li>Netradiční rodinný dům 2x 2+kk včetně dvou garáží</li>
                        <li>Podsklepený ve svažitém pozemků víceúrovňový</li>
                        <li>Zděný zateplený kontaktním pláštěm tl. 120mm</li>
                        <li>Stropy železobetonové s keramickými vložkami</li>
                        <li>Konstrukce střechy dřevěná</li>
                        <li>Střešní krytina betonová</li>
                        <li>Okna plastová s izolačním dvojsklem</li>
                        <li>Garážová vrata zateplená sekční</li>
                        <li>Suterén železobetonový s vodostavebního betonu</li>
                        <li>Objekt připojen na veškeré inženýrské sítě</li>
                        <li>Objekt vytápěn plynovým kondenzačním kotlem kombinovaným se zásobníkem TUV</li></ul>
                </div>
                <ImageGallery showPlayButton={false} showFullscreenButton={false} showBullets={true} items={images1} />
            </div>
        </section>
        <section id="section-2-pro-5">
            <div className="pozemni">
                <div className="picovinky-veci">
                    <h3>Rodinný dům v Horních Počernicích - Svépravice</h3>
                    <ul><li>Projekt pro stavební povolení</li>
                        <li>Částečně podsklepený rodinný dům 5+1 včetně garáže pro dvě osobní vozidla, umístěný ve svažitém pozemku</li>
                        <li>Suterén železobetonový, zdivo z cihelných bloků</li>
                        <li>Obvodové zdivo zatepleno kontaktním pláštěm tl. 120mm</li>
                        <li>Strop z žb prefabrikátů</li>
                        <li>Střešní konstrukce dřevěná na ocelových sloupech</li>
                        <li>Střešní krytina palená taška</li>
                        <li>Okna plastová potažená fólií v barvě</li>
                        <li>Terasa krytá pergolou</li>
                        <li>Chodníky okolo domu ze zámkové dlažby</li>
                        <li>Objekt připojen na veškeré inženýrské sítě</li>
                        <li>Vytápění plynovým kondenzačním kotlem zapojeným v kombinaci se zásobníkem teplé užitkové vody</li>
                        <li>Topení kombinované (podlahové, radiátory)</li></ul>
                </div>
                <ImageGallery showPlayButton={false} showFullscreenButton={false} showBullets={true} items={images2} />
            </div>
        </section>
        <section id="section-3-pro-5">
            <div className="pozemni">
                <div className="picovinky-veci">
                    <h3>Rodinný dům Stránčice</h3>
                    <ul><li>Nepodsklepený rodinný dům 7+1 včetně garáže pro osobní vůz</li>
                        <li>2 koupelny (1x v přízemí, 1x v podkroví)</li>
                        <li>Dům umístěn na rovinatém pozemku</li>
                        <li>Objekt domu připojen na veškeré inženýrské sítě</li>
                        <li>Zdivo z porobetonových bloků, stropy železobetonové vylehčené z porobetonových vložek</li>
                        <li>Krov dřevěný, Střešní krytina-betonová taška</li>
                        <li>Okna plastová s izolačním dvosklem, Objekt zateplen kontaktním pláštěm 12cm</li>
                        <li>Podkroví zatepleno minerální vatou tl.24cm</li>
                        <li>Dům vytápěn plynovým kondenzačním kotlem s kombinovaným zásobníkem pro přípravu teplé užitkové vody</li></ul>
                </div>
                <ImageGallery showPlayButton={false} showFullscreenButton={false} showBullets={true} items={images3} />
            </div>
        </section>
        <section id="section-4-pro-5">
            <div className="pozemni">
                <div className="picovinky-veci">
                    <h3>Rodinný dům Dubeč</h3>
                    <ul><li>Projekt pro stavební povolení, technický dozor investora</li>
                        <li>Rekonstrukce rodinného domu</li>
                        <li>Kompletní výměna střechy, zateplení všech obvodových konstrukcí, výměna oken, přizpůsobení dispozice podřebám klienta</li>
                        <li>Střešní konstrukce dřevěná, mansardového typu</li>
                        <li>Střešní taška pálená</li>
                        <li>Zateplení podkroví minerální izolací tl. 240mm</li>
                        <li>Okna dřevěná s izolačním dvojsklem</li>
                        <li>Obvodové konstrukce z cihelného zdiva zatepleny kontaktním pláštěm tl. 120mm</li>
                        <li>V 1.np použit sanační systém proti vlhkému zdivu</li>
                        <li>Vytápění tepelným čerpadlem vzduch-voda</li>
                        <li>Kombinace vytápění (podlahové, radiátory)</li>
                        <li>V objektu instalována vířivka a sauna</li></ul>
                </div>
                <ImageGallery showPlayButton={false} showFullscreenButton={false} showBullets={true} items={images4} />
            </div>
        </section>
        <section id="section-5-pro-5">
            <div className="pozemni">
                <div className="picovinky-veci">
                    <h3>Rodinný dům Na Chvalce Horní Počernice</h3>
                    <ul><li>Nepodsklepený rodinný dům 5+1 umístěný na rovinném pozemku</li>
                        <li>Zděný z cihelných bloků zateplený kontaktním pláštěm tl. 120mm</li>
                        <li>Stropy železobetonové s keramickými vložkami</li>
                        <li>Střešní konstrukce z dřevěných vazníků</li>
                        <li>Střešní krytina z betonových tašek</li>
                        <li>Okna plastová s izolačním dvojsklem</li>
                        <li>Objekt vyprojektován včetně veřejných částí přípojek inženýrských sítí a zpracováním oddělovacího plánu</li>
                        <li>Dům vytápěn elektrickým kotlem kombinovaným se zásobníkem TUV</li></ul>
                </div>
                <ImageGallery showPlayButton={false} showFullscreenButton={false} showBullets={true} items={images5} />
            </div>
        </section>
        <section id="strankovani">
            <div class="center">
                <div class="pagination-relas">
                    <Link to="../reference/projekty">1</Link>
                    <Link to="../reference/projekty-2">2</Link>
                    <Link to="../reference/projekty-3">3</Link>
                    <Link to="../reference/projekty-4">4</Link>
                    <Link className="active">5</Link>
                    <Link to="../reference/projekty-6">6</Link>
                </div>
            </div>
        </section>
    </div>
}

export default Projekty5