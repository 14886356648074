import { React } from "react";
import "./InstTopPrace.css"
import ImageGallery from 'react-image-gallery';

const images1 = [
    {
        original: 'https://i.imgur.com/CsgVaiJ.jpg',
        thumbnail: 'https://i.imgur.com/CsgVaiJ.jpg',
    },
    {
        original: 'https://i.imgur.com/MpQ98NP.jpg',
        thumbnail: 'https://i.imgur.com/MpQ98NP.jpg',
    },
    {
        original: 'https://i.imgur.com/SgCLVWJ.jpg',
        thumbnail: 'https://i.imgur.com/SgCLVWJ.jpg',
    },
    {
        original: 'https://i.imgur.com/BZoZodt.jpg',
        thumbnail: 'https://i.imgur.com/BZoZodt.jpg',
    },
    {
        original: 'https://i.imgur.com/dA4TaW6.jpg',
        thumbnail: 'https://i.imgur.com/dA4TaW6.jpg',
    },
];

const InstTopPrace = () => {
    return <div>
        <section id="section-1-top-1">
            <div className="pozemni">
                <div className="nadpis-pozemni">
                    <h2>Reference - Zámečnictví</h2>
                </div>
                <div className="picovinky-veci">
                    <h3>Zábradlí Vestec u Prahy</h3>
                    <p>Dodávka a montáž žárově pozinkovaného zábradlí na veřejném prostranství ve Vestci u Prahy</p>
                </div>
                <ImageGallery showPlayButton={false} showFullscreenButton={false} showBullets={true} items={images1} />
            </div>
        </section>
    </div>
}

export default InstTopPrace