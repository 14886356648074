import { useRef, useState } from "react"
import { Helmet } from "react-helmet";
import emailjs from '@emailjs/browser';
import "./Kontakt.css"
import pozadi from "../img/336613713_912673999781126_845675244165494537_n.jpg"
import { FaPhoneAlt, FaClock, FaLocationArrow } from "react-icons/fa";
const Kontakt = () => {

    const [confirmed, setConfirmed] = useState(false)
    const [hideSubmit, setHideSubmit] = useState(true)

    const form = useRef()

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs.sendForm("service_46ktyd9", "testing_e9nvmqw", form.current, 'RwVLbRXkwutrYo9Er')
            .then((result) => {
                console.log(result.text);
                setHideSubmit(false)
                setConfirmed(true);
            }, (error) => {
                console.log(error.text);
            });

    }

    return <div>
        <Helmet>
<title>Kontakt</title>
<meta name="title" content="EKIS - Kontakt"/>
<meta name="description" content=""/>
<meta property="og:type" content="website"/>
<meta property="og:url" content="http://ekis-as.cz/kontakt"/>
<meta property="og:title" content="EKIS - Kontakt"/>
<meta property="og:description" content=""/>
<meta property="og:image" content=""/>
        </Helmet>
        <section id="img-sekce">
            <img className="pozadi" src={pozadi} alt="pozadi" data-image-width="1280" data-image-height="853" />
            <div className="wrapper">
                <div className="kontaktujte-nas">
                    <h3>Kontaktujte nás</h3>
                    <form ref={form} onSubmit={sendEmail} style={{ padding: '10px' }}>
                        <input type="text" placeholder="Zadejte své jméno" name="user_name" required />
                        <input name="user_email" type="email" placeholder="Zadejte svou emailovou adresu" required />
                        <input name="tel_number" type="tel" placeholder="Zadejte své telefonní číslo" pattern="\+?\d{0,3}[\s\(\-]?([0-9]{2,3})[\s\)\-]?([\s\-]?)([0-9]{3})[\s\-]?([0-9]{2})[\s\-]?([0-9]{2})" required />
                        <select name="subject" id="selector-form">
                            <option value="Zájem o pracovní pozici">Zájem o pracovní pozici</option>
                            <option value="Dotaz do sekce poradenství">Dotaz do sekce poradenství</option>
                            <option value="Dotaz ohledně internetových stránek">Dotaz ohledně internetových stránek</option>
                            <option value="Dotaz na výrobní oddělení">Dotaz na výrobní oddělení</option>
                            <option value="Dotaz na obchodní oddělení">Dotaz na obchodní oddělení</option>
                            <option value="Jiný...">Jiný...</option>
                        </select>
                        <textarea rows="4" cols="50" name="zprava" required="" style={{ height: "119px", resize: "none" }}></textarea>
                        {hideSubmit && (
                            <input type="submit" className="submit" value="Odeslat" wfd-invisible="true"></input>
                        )}
                        {confirmed && (
                            <p className="confirmedEmail">Zpráva byla odeslána.</p>
                        )}
                    </form>
                </div>
                <div className="kontakt-info">
                    <div className="telefonni-cislo">
                        <FaPhoneAlt className="kontakt-ikonky" /><h5>Telefonní číslo</h5>
                        <p>TEL: <a href="tel:+420281040611">+420 281 040 611</a> <br />
                            FAX: <a href="tel:+420281040699">+420 281 040 699</a> <br />
                            GSM: <a href="tel:+420724080948">+420 724 080 948</a> <br />
                            MAIL: <a href="mailto:ekis@ekis-as.cz">ekis@ekis-as.cz</a></p>
                    </div>
                    <div className="adresa">
                        <FaLocationArrow className="kontakt-ikonky" />
                        <h5>Adresa</h5>
                        <p>Náchodská 2421 <br />
                            Praha 9 <br />
                            Horní Počernice <br />
                            193 00</p>
                    </div>
                    <div className="provozni-doba">
                        <FaClock className="kontakt-ikonky" />
                        <h5>Provozní doba</h5>
                        <p>Pondělí 7-16 <br />
                            Úterý 7-16 <br />
                            Středa 7-16 <br />
                            Čtvrtek 7-16 <br />
                            Pátek 7-16 <br />
                            So-Ne Zavřeno</p>
                    </div>
                </div>
                <div className="mapicka">
                    <iframe title="Mapa Kontakt" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2238.6250850793626!2d14.631248143754162!3d50.11741621844452!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x470bf2f1c33de1bd%3A0x84b02ec755751410!2zRWtvbG9naWNrw6kgYSBpbsW-ZW7DvXJza8OpIHN0YXZieSBzLnIuby4!5e0!3m2!1scs!2scz!4v1677017540634!5m2!1scs!2scz" width="1000" height="450" style={{ border: "0" }} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                </div>
                <div className="mapicka-mobil">
                    <iframe title="Mapa EKIS" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1084.4296411148166!2d14.631828984558231!3d50.117760724684835!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x470bf2f1c33de1bd%3A0x84b02ec755751410!2zRWtvbG9naWNrw6kgYSBpbsW-ZW7DvXJza8OpIHN0YXZieSBzLnIuby4!5e0!3m2!1scs!2scz!4v1677017362096!5m2!1scs!2scz" width="340" height="450" style={{ border: "0" }} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                </div>
            </div>
        </section>
    </div>
}

export default Kontakt