import { React } from "react";
import { Link } from "react-router-dom";
import "./Pozemni-stavby.css"
import ImageGallery from 'react-image-gallery';

const images1 = [
  {
    original: 'https://i.imgur.com/yOA9FbC.jpg',
    thumbnail: 'https://i.imgur.com/yOA9FbC.jpg',
  },
  {
    original: 'https://i.imgur.com/VF6Hh0h.jpg',
    thumbnail: 'https://i.imgur.com/VF6Hh0h.jpg',
  },
  {
    original: 'https://i.imgur.com/u7uGf71.jpg',
    thumbnail: 'https://i.imgur.com/u7uGf71.jpg',
  },
];

const images2 = [
  {
    original: 'https://i.imgur.com/FLwiiNv.jpg',
    thumbnail: 'https://i.imgur.com/FLwiiNv.jpg',
  },
  {
    original: 'https://i.imgur.com/eA5MDCP.jpg',
    thumbnail: 'https://i.imgur.com/eA5MDCP.jpg',
  },
  {
    original: 'https://i.imgur.com/UiuNaSv.jpg',
    thumbnail: 'https://i.imgur.com/UiuNaSv.jpg',
  },
  {
    original: 'https://i.imgur.com/vJxYhbc.jpeg',
    thumbnail: 'https://i.imgur.com/vJxYhbc.jpeg',
  },
  {
    original: 'https://i.imgur.com/l2KlfHk.jpeg',
    thumbnail: 'https://i.imgur.com/l2KlfHk.jpeg',
  },
  {
    original: 'https://i.imgur.com/rGqLFOD.jpeg',
    thumbnail: 'https://i.imgur.com/rGqLFOD.jpeg',
  },
  {
    original: 'https://i.imgur.com/4wwncVS.jpeg',
    thumbnail: 'https://i.imgur.com/4wwncVS.jpeg',
  },
  {
    original: 'https://i.imgur.com/WneyapL.jpeg',
    thumbnail: 'https://i.imgur.com/WneyapL.jpeg',
  },
  {
    original: 'https://i.imgur.com/f0LbD8J.jpeg',
    thumbnail: 'https://i.imgur.com/f0LbD8J.jpeg',
  },
  {
    original: 'https://i.imgur.com/QUscwWs.jpeg',
    thumbnail: 'https://i.imgur.com/QUscwWs.jpeg',
  },
  {
    original: 'https://i.imgur.com/nrnMDFH.jpeg',
    thumbnail: 'https://i.imgur.com/nrnMDFH.jpeg',
  },
  {
    original: 'https://i.imgur.com/RqzmEhK.jpeg',
    thumbnail: 'https://i.imgur.com/RqzmEhK.jpeg',
  },
  {
    original: 'https://i.imgur.com/ShsxBGt.jpeg',
    thumbnail: 'https://i.imgur.com/ShsxBGt.jpeg',
  },
  {
    original: 'https://i.imgur.com/DCo6azv.jpeg',
    thumbnail: 'https://i.imgur.com/DCo6azv.jpeg',
  },
];

const images3 = [
  {
    original: 'https://i.imgur.com/uLYHPi9.jpg',
    thumbnail: 'https://i.imgur.com/uLYHPi9.jpg',
  },
  {
    original: 'https://i.imgur.com/mmRYdNm.jpg',
    thumbnail: 'https://i.imgur.com/mmRYdNm.jpg',
  },
  {
    original: 'https://i.imgur.com/hjkofDa.jpg',
    thumbnail: 'https://i.imgur.com/hjkofDa.jpg',
  },
  {
    original: 'https://i.imgur.com/xWvvvsX.jpg',
    thumbnail: 'https://i.imgur.com/xWvvvsX.jpg',
  },
];

const images4 = [
  {
    original: 'https://i.imgur.com/7Q5nFp7.jpg',
    thumbnail: 'https://i.imgur.com/7Q5nFp7.jpg',
  },
  {
    original: 'https://i.imgur.com/VmB4aGr.jpg',
    thumbnail: 'https://i.imgur.com/VmB4aGr.jpg',
  },
  {
    original: 'https://i.imgur.com/fVjLnc6.jpg',
    thumbnail: 'https://i.imgur.com/fVjLnc6.jpg',
  },
  {
    original: 'https://i.imgur.com/6Y9tyre.jpg',
    thumbnail: 'https://i.imgur.com/6Y9tyre.jpg',
  },
  {
    original: 'https://i.imgur.com/mrQpOMX.jpg',
    thumbnail: 'https://i.imgur.com/mrQpOMX.jpg',
  },
  {
    original: 'https://i.imgur.com/O8k26Op.jpg',
    thumbnail: 'https://i.imgur.com/O8k26Op.jpg',
  },
  {
    original: 'https://i.imgur.com/QJqM8CJ.jpg',
    thumbnail: 'https://i.imgur.com/QJqM8CJ.jpg',
  },
];


const images5 = [
  {
    original: 'https://i.imgur.com/Bh60wA7.jpg',
    thumbnail: 'https://i.imgur.com/Bh60wA7.jpg',
  },
  {
    original: 'https://i.imgur.com/3U0sAIn.jpg',
    thumbnail: 'https://i.imgur.com/3U0sAIn.jpg',
  },
  {
    original: 'https://i.imgur.com/fpINTri.jpg',
    thumbnail: 'https://i.imgur.com/fpINTri.jpg',
  },
  {
    original: 'https://i.imgur.com/EvaodQh.jpg',
    thumbnail: 'https://i.imgur.com/EvaodQh.jpg',
  },
  {
    original: 'https://i.imgur.com/zYUIA9z.jpg',
    thumbnail: 'https://i.imgur.com/zYUIA9z.jpg',
  },
  {
    original: 'https://i.imgur.com/CqrYEN8.jpg',
    thumbnail: 'https://i.imgur.com/CqrYEN8.jpg',
  },
  {
    original: 'https://i.imgur.com/RaAmwW7.jpg',
    thumbnail: 'https://i.imgur.com/RaAmwW7.jpg',
  },
];

const PozemniStavby = () => {
  return <div>
    <section id="section-1">
      <div className="pozemni">
        <div className="nadpis-pozemni">
          <h2>Reference - Pozemní stavby</h2>
        </div>
        <div className="picovinky-veci">
          <h3>MŠ Břežany II</h3>
          <ul>
            <li>Novostavba objektu mateřské školy s gastro provozem</li>
            <li>Inženýrské sítě a parkoviště</li>
            <li>Dětské hřiště</li>
            <li>Sadové úpravy</li>
            <li>Oplocení</li>
          </ul>
        </div>
        <ImageGallery showPlayButton={false} showFullscreenButton={false} showBullets={true} items={images1} />
      </div>
    </section>
    <section id="section-2">
      <div className="pozemni">
        <div className="picovinky-veci">
          <h3>Biotop Radotín</h3>
          <ul>
            <li> Šatny
            </li>
            <li>Sprchy
            </li>
            <li>Technické zázemí
            </li>
            <li>Terasa s rampou
            </li>
            <li>Občerstvení </li>
          </ul>
        </div>
        <ImageGallery showPlayButton={false} showFullscreenButton={false} showBullets={true} items={images2} />
      </div>
    </section>
    <section id="section-3">
      <div className="pozemni">
        <div className="picovinky-veci">
          <h3>Bytové domy Mladá Boleslav</h3>
          <p>Novostavby bytových domů v ulici Máchova v Mladé Boleslavi. Celkem se zde nachází 10 bytů a 3 komerční prostory. Areál zahrnuje parkovací plochy.</p>
        </div>
        <ImageGallery showPlayButton={false} showFullscreenButton={false} showBullets={true} items={images3} />
      </div>
    </section>
    <section id="section-4">
      <div className="pozemni">
        <div className="picovinky-veci">
          <h3>Zateplení panelového domu Mezilesí</h3>
          <ul>
            <li>Sanace panelových konstrukcí</li>
            <li>Zateplovací systém ETICS</li>
            <li>Výměna klempířských konstrukcí</li>
            <li>Výměna zámečnických konstrukcí</li>
            <li>Sanace balkónů</li>
            <li>Nové izolace a dlažba</li>
          </ul>
        </div>
        <ImageGallery showPlayButton={false} showFullscreenButton={false} showBullets={true} items={images4} />
      </div>
    </section>
    <section id="section-5">
      <div className="pozemni">
        <div className="picovinky-veci">
          <h3>Zateplení bytového domu, ulice Vybíralova, Černý Most</h3>
          <ul><li>zateplení obvodového pláště</li>
            <li>rekonstrukce lodžií</li>
            <li>nové klempířské prvky</li>
            <li>nové zámečnické prvky</li></ul>
        </div>
        <ImageGallery showPlayButton={false} showFullscreenButton={false} showBullets={true} items={images5} />
      </div>
    </section>
    <section id="strankovani">
    <div class="center">
  <div class="pagination-relas">
  <Link className="active">1</Link>
  <Link to="../reference/pozemni-stavby-2">2</Link>
  <Link to="../reference/pozemni-stavby-3">3</Link>
  <Link to="../reference/pozemni-stavby-4">4</Link>
  </div>
</div>
    </section>
  </div>
}

export default PozemniStavby