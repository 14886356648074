import "./Question.css";
import { useState } from "react";
import { FaPlus, FaMinus } from "react-icons/fa";

const Question = ({title, info}) => {
    const [show, setShow] = useState(false);

    return (
        <div className="one-question">
            <div className="question-header" onClick={() => setShow(!show)}>
                <h3 className="question-h3">{title}</h3>
                {show ? <FaMinus /> : <FaPlus />}
            </div>
            {show && <p>{info}</p>}
        </div>
    );
}

export default Question;
