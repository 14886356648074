import { React } from "react";
import { Link } from "react-router-dom";
import "./Zamecnictvi.css"
import ImageGallery from 'react-image-gallery';

const images1 = [
    {
        original: 'https://i.imgur.com/li82Czi.jpg',
        thumbnail: 'https://i.imgur.com/li82Czi.jpg',
    },
    {
        original: 'https://i.imgur.com/AripIbM.jpg',
        thumbnail: 'https://i.imgur.com/AripIbM.jpg',
    },
    {
        original: 'https://i.imgur.com/QwoIphO.jpg',
        thumbnail: 'https://i.imgur.com/QwoIphO.jpg',
    },
    {
        original: 'https://i.imgur.com/5tiiFml.jpg',
        thumbnail: 'https://i.imgur.com/5tiiFml.jpg',
    },
    {
        original: 'https://i.imgur.com/r5KqtnK.jpg',
        thumbnail: 'https://i.imgur.com/r5KqtnK.jpg',
    },
    {
        original: 'https://i.imgur.com/pfqfaKN.jpg',
        thumbnail: 'https://i.imgur.com/pfqfaKN.jpg',
    },
];

const Zamecnictvi2 = () => {
    return <div>
        <section id="section-1-zam-2">
            <div className="pozemni">
                <div className="nadpis-pozemni">
                    <h2>Reference - Zámečnictví</h2>
                </div>
                <div className="picovinky-veci">
                    <h3>Zábradlí Vestec u Prahy</h3>
                    <p>Dodávka a montáž žárově pozinkovaného zábradlí na veřejném prostranství ve Vestci u Prahy</p>
                </div>
                <ImageGallery showPlayButton={false} showFullscreenButton={false} showBullets={true} items={images1} />
            </div>
        </section>
        <section id="strankovani">
            <div class="center">
                <div class="pagination-relas">
                    <Link to="../reference/zamecnictvi">1</Link>
                    <Link className="active">2</Link>
                </div>
            </div>
        </section>
    </div>
}

export default Zamecnictvi2