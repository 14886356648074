import { Helmet } from "react-helmet";
import "./Reference.css"
const Reference = () => {

    return <div>
        <Helmet>
<title>Reference</title>
<meta name="title" content="EKIS - Reference"/>
<meta name="description" content=""/>
<meta property="og:type" content="website"/>
<meta property="og:url" content="http://ekis-as.cz/reference"/>
<meta property="og:title" content="EKIS - Reference"/>
<meta property="og:description" content=""/>
<meta property="og:image" content=""/>
        </Helmet>
        <section id="reference">
            <div className="nadpis-reference">
        <h2>Reference - referenční stavby</h2>
        </div>
        <div className="obrazky-refe">
            <img src="https://i.imgur.com/oCh7Kak.png" alt="" />
            <div className="obrazek-one"></div>
            <h4>Rekonstrukce rychty v Klecanech​</h4>
            <img src="https://i.imgur.com/uHbEYiR.png" alt="" />
            <div className="obrazek-one"></div>
            <h4>Zdravotní centrum KOSMOS, Praha 4​</h4>
            <img src="https://i.imgur.com/P3C0UB3.png" alt="" />
            <div className="obrazek-one">
            <h4>Rodinný dům v Krušných horách​</h4>
            <img src="https://i.imgur.com/lKTpAss.png" alt="" />
            </div>
            <div className="obrazek-one">
            <h4>Hřbitov u kaple Svatého Václava v Suchdole​</h4>
            <img src="https://i.imgur.com/MlKqtk3.png" alt="" />
            </div>
            <div className="obrazek-one">
            <h4>Křižovatka Karlická Pod Kaplicí v Radotíně​​</h4>
            <img src="https://i.imgur.com/KDFc9T4.png" alt="" />
            </div>
            <div className="obrazek-one">
            <h4>Rekonstrukce areálu MŠ a ZŠ v Kolodějích​​</h4>
            <img src="https://i.imgur.com/aL7uXQS.png" alt="" />
            </div>
            <div className="obrazek-one">
            <h4>Rekonstrukce ulice Zbuzanská v Řepích​​</h4>
            <img src="https://i.imgur.com/Dk9Pp0M.png" alt="" />
            </div>
            <div className="obrazek-one">
            <h4>Parkoviště P+R v Hostivaři​​​</h4>
            <img src="https://i.imgur.com/8qfvEyT.png" alt="" />
            </div>
            <div className="obrazek-one">
            <h4>Rekonstrukce ulice Na Cihelně ve Velké Chuchli​​​​</h4>
            <img src="https://i.imgur.com/wYT5XsO.png" alt="" />
            </div>
            <div className="obrazek-one">
                <h4>Rekonstrukce komunikace v Kolodějích</h4>
            <img src="https://i.imgur.com/ETI6gO4.png" alt="" />
            </div>
            <div className="obrazek-one">
                <h4>Rodinné domy v Krušných horách​</h4>
            <img src="https://i.imgur.com/f0j2p5J.png" alt="" />
            </div>
            <div className="obrazek-one">
                <h4>Rekonstrukce ulice U Náhonu ve Slivenci​​</h4>
            <img src="https://i.imgur.com/ypctjgN.png" alt="" />
            </div>
            <div className="obrazek-one">
                <h4>Logistický areál GTL s.r.o. v Nehvizdech​​</h4>
            <img src="https://i.imgur.com/l0b5UuF.png" alt="" />
            </div>
            <div className="obrazek-one">
                <h4>Přístavba tělocvičny v Průhonicích​</h4>
            <img src="https://i.imgur.com/YLSiW7h.png" alt="" />
            </div>
            <div className="obrazek-one">
                <h4>Mateřská škola v Průhonicích​</h4>
            <img src="https://i.imgur.com/0va9rhZ.png" alt="" />
            </div>
            <div className="obrazek-one">
                <h4>Komunitní centrum v Hloubětíně​​</h4>
            <img src="https://i.imgur.com/PcZfCdX.png" alt="" />
            </div>
            <div className="obrazek-one">
                <h4>Rekonstrukce Základní školy v Líbeznicích​​​</h4>
            <img src="https://i.imgur.com/fqEFcol.png" alt="" />
            </div>
            <div className="obrazek-one">
                <h4>Mateřská škola v Hostavicích​​</h4>
            <img src="https://i.imgur.com/O1vcAK2.png" alt="" />
            </div>
            <div className="obrazek-one">
                <h4>Obchodní centrum Billa v Komořanech​​</h4>
            <img src="https://i.imgur.com/EVgIkiz.png" alt="" />
            </div>
            <div className="obrazek-one">
                <h4>Základní škola ve Vysočanech​​</h4>
            <img src="https://i.imgur.com/dIY1Uhs.png" alt="" />
            </div>
            <div className="obrazek-one">
                <h4>Rekonstrukce ulice Nad Zavážkou v Modřanech​</h4>
            <img src="https://i.imgur.com/hispXrZ.png" alt="" />
            </div>
            <div className="obrazek-one">
                <h4>Cyklostezka Lochkov Slivenec​</h4>
            <img src="https://i.imgur.com/HcLb2ye.png" alt="" />
            </div>
            <div className="obrazek-one">
                <h4>Skladová hala a kanceláře Horní Počernice​​</h4>
            <img src="https://i.imgur.com/CuL5rww.png" alt="" />
            </div>
            <div className="obrazek-one">
                <h4>Vzduchotechnika a elektroinstalace ZŠ Suchdol​​</h4>
            <img src="https://i.imgur.com/JyRDloJ.png" alt="" />
            </div>
            <div className="obrazek-one">
                <h4>Skladová hala Horní Počernice​​</h4>
            <img src="https://i.imgur.com/v40pOkD.png" alt="" />
            </div>
            <div className="obrazek-one">
                <h4>Biotop Radotín​​</h4>
            <img src="https://i.imgur.com/MxMBEq9.png" alt="" />
            </div>
            <div className="obrazek-one">
                <h4>Rekonstrukce komunikace v Hloubětíně​​</h4>
            <img src="https://i.imgur.com/0kITBKt.png" alt="" />
            </div>
        </div>
        </section>
    </div>
}

export default Reference