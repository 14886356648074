import { React } from "react";
import { Link } from "react-router-dom";
import "./InzenyrskeStavby.css"
import ImageGallery from 'react-image-gallery';

const images1 = [
    {
        original: 'https://i.imgur.com/gkZLV41.jpg',
        thumbnail: 'https://i.imgur.com/gkZLV41.jpg',
    },
    {
        original: 'https://i.imgur.com/id7gaBp.jpg',
        thumbnail: 'https://i.imgur.com/id7gaBp.jpg',
    },
    {
        original: 'https://i.imgur.com/77mMjK2.jpg',
        thumbnail: 'https://i.imgur.com/77mMjK2.jpg',
    },
    {
        original: 'https://i.imgur.com/7JaBqQA.jpg',
        thumbnail: 'https://i.imgur.com/7JaBqQA.jpg',
    },
];

const images2 = [
    {
        original: 'https://i.imgur.com/Mqul92E.jpg',
        thumbnail: 'https://i.imgur.com/Mqul92E.jpg',
    },
    {
        original: 'https://i.imgur.com/wxtwzRm.jpg',
        thumbnail: 'https://i.imgur.com/wxtwzRm.jpg',
    },
    {
        original: 'https://i.imgur.com/6Dqfwhv.jpg',
        thumbnail: 'https://i.imgur.com/6Dqfwhv.jpg',
    },
    {
        original: 'https://i.imgur.com/zciGorm.jpg',
        thumbnail: 'https://i.imgur.com/zciGorm.jpg',
    },
    {
        original: 'https://i.imgur.com/lUtX5TF.jpg',
        thumbnail: 'https://i.imgur.com/lUtX5TF.jpg',
    },
    {
        original: 'https://i.imgur.com/YnND9Dg.jpg',
        thumbnail: 'https://i.imgur.com/YnND9Dg.jpg',
    },
    {
        original: 'https://i.imgur.com/PkfI47A.jpg',
        thumbnail: 'https://i.imgur.com/PkfI47A.jpg',
    },
    {
        original: 'https://i.imgur.com/YAEW5CM.jpg',
        thumbnail: 'https://i.imgur.com/YAEW5CM.jpg',
    },
    {
        original: 'https://i.imgur.com/PpQeJdl.jpg',
        thumbnail: 'https://i.imgur.com/PpQeJdl.jpg',
    },
    {
        original: 'https://i.imgur.com/r07uQyT.jpg',
        thumbnail: 'https://i.imgur.com/r07uQyT.jpg',
    },
];

const images3 = [
    {
        original: 'https://i.imgur.com/PLo5RmZ.jpg',
        thumbnail: 'https://i.imgur.com/PLo5RmZ.jpg',
    },
    {
        original: 'https://i.imgur.com/bvqSPlH.jpg',
        thumbnail: 'https://i.imgur.com/bvqSPlH.jpg',
    },
    {
        original: 'https://i.imgur.com/7etmq6t.jpg',
        thumbnail: 'https://i.imgur.com/7etmq6t.jpg',
    },
    {
        original: 'https://i.imgur.com/ROvxLRH.jpg',
        thumbnail: 'https://i.imgur.com/ROvxLRH.jpg',
    },
];

const images4 = [
    {
        original: 'https://i.imgur.com/n4jhBDw.jpg',
        thumbnail: 'https://i.imgur.com/n4jhBDw.jpg',
    },
    {
        original: 'https://i.imgur.com/qgRf2WT.jpg',
        thumbnail: 'https://i.imgur.com/qgRf2WT.jpg',
    },
    {
        original: 'https://i.imgur.com/g7EmQhE.jpg',
        thumbnail: 'https://i.imgur.com/g7EmQhE.jpg',
    },
    {
        original: 'https://i.imgur.com/2LTlzuu.jpg',
        thumbnail: 'https://i.imgur.com/2LTlzuu.jpg',
    },
];


const images5 = [
    {
        original: 'https://i.imgur.com/CRufbBb.jpg',
        thumbnail: 'https://i.imgur.com/CRufbBb.jpg',
    },
    {
        original: 'https://i.imgur.com/MtoKI2t.jpg',
        thumbnail: 'https://i.imgur.com/MtoKI2t.jpg',
    },
    {
        original: 'https://i.imgur.com/tb0qLL6.jpg',
        thumbnail: 'https://i.imgur.com/tb0qLL6.jpg',
    },
    {
        original: 'https://i.imgur.com/oFr2mOZ.jpg',
        thumbnail: 'https://i.imgur.com/oFr2mOZ.jpg',
    },
    {
        original: 'https://i.imgur.com/7a6FIfI.jpg',
        thumbnail: 'https://i.imgur.com/7a6FIfI.jpg',
    },
    {
        original: 'https://i.imgur.com/LnHcP1y.jpg',
        thumbnail: 'https://i.imgur.com/LnHcP1y.jpg',
    },
];

const InzenyrskeStavby4 = () => {
    return <div>
        <section id="section-1">
            <div className="pozemni">
                <div className="nadpis-pozemni">
                    <h2>Reference - Inženýrské stavby</h2>
                </div>
                <div className="picovinky-veci">
                    <h3>Rekonstrukce DVÚ Jana Masaryka</h3>
                </div>
                <ImageGallery showPlayButton={false} showFullscreenButton={false} showBullets={true} items={images1} />
            </div>
        </section>
        <section id="section-2">
            <div className="pozemni">
                <div className="picovinky-veci">
                    <h3>Inženýrské sítě a komunikace v Horních Počernicích</h3>
                </div>
                <ImageGallery showPlayButton={false} showFullscreenButton={false} showBullets={true} items={images2} />
            </div>
        </section>
        <section id="section-3">
            <div className="pozemni">
                <div className="picovinky-veci">
                    <h3>Dobřichovice Za Školou</h3>
                </div>
                <ImageGallery showPlayButton={false} showFullscreenButton={false} showBullets={true} items={images3} />
            </div>
        </section>
        <section id="section-4">
            <div className="pozemni">
                <div className="picovinky-veci">
                    <h3>TV Dolní Počernice</h3>
                </div>
                <ImageGallery showPlayButton={false} showFullscreenButton={false} showBullets={true} items={images4} />
            </div>
        </section>
        <section id="section-5-inz-3">
            <div className="pozemni">
                <div className="picovinky-veci">
                    <h3>Vodovod Dobřichovice</h3>
                </div>
                <ImageGallery showPlayButton={false} showFullscreenButton={false} showBullets={true} items={images5} />
            </div>
        </section>
        <section id="strankovani">
            <div class="center">
                <div class="pagination-relas">
                    <Link to="../reference/inzenyrske-stavby">1</Link>
                    <Link to="../reference/inzenyrske-stavby-2" >2</Link>
                    <Link to="../reference/inzenyrske-stavby-3">3</Link>
                    <Link className="active">4</Link>
                    <Link to="../reference/inzenyrske-stavby-5">5</Link>
                </div>
            </div>
        </section>
    </div>
}

export default InzenyrskeStavby4