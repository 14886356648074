import { Helmet } from "react-helmet";
import "./Spolecnost.css"
import instavby from "../img/KTojBAk.png"
import pozstavby from "../img/xCPAUFy.png"
import projektstavby from "../img/l2TS6nE.png"
import specialstavby from "../img/1FViIH3.png"
const Spolecnost = () => {

  return <div>
    <Helmet>
<title>O společnosti</title>
<meta name="title" content="EKIS - O společnosti"/>
<meta name="description" content=""/>
<meta property="og:type" content="website"/>
<meta property="og:url" content="http://ekis-as.cz/spolecnost"/>
<meta property="og:title" content="EKIS - O společnosti"/>
<meta property="og:description" content=""/>
<meta property="og:image" content=""/>
            </Helmet>
    <section id="o-spolecnosti">
      <div className="main-items">
        <div className="h2h3">
          <h2>O společnosti</h2>
          <h3>Základní informace o společnosti</h3>
        </div>
        <div className="popisek-spolecnost">
          <p>Společnost Ekologické a inženýrské stavby, spol. s r.o. (zkratka EKIS, spol. s r.o.) byla založena v roce 1991 zápisem do obchodního rejstříku vedeného Městským soudem v Praze v oddílu C, vložka 2362. <a href="https://or.justice.cz/ias/ui/rejstrik-firma.vysledky?subjektId=427339&typ=PLATNY"> Obchodní rejstřík</a></p>
        </div>
        <div className="cinnosti-firmy">
        <h3>Činnosti společnosti</h3>
        <h4>Výstavba inženýrských staveb</h4>
        <img src={instavby} alt="stavby in" />
        <ul>
          <li>Příprava území</li>
          <li>Zemní práce</li>
          <li>Výstavba kanalizačních stok včetně všech objektů a přípojek</li>
          <li>Výstavba vodovodních řadů včetně všech objektů a přípojek</li>
          <li>Výstavba plynovodních řadů včetně všech objektů a přípojek</li>
          <li>Komunikace</li>
          <li>Místní komunikace</li>
          <li>Zpevnění ploch včetně doplňkových komunikací (chodníky, krajnice, vodorovné a svislé značení apod.)</li>
        </ul>
        <h4>Výstavba pozemních staveb</h4>
        <img src={pozstavby} alt="" />
        <ul>
          <li>Demolice a zemní práce</li>
          <li>Příprava území</li>
          <li>Zemní práce</li>
          <li>Výstavba pozemních staveb</li>
          <li>Novostavby občanských, bytových a průmyslových objektů</li>
          <li>Opravy a celkové rekonstrukce objektů pozemních staveb</li>
          <li>Novostavby a rekonstrukce rodinných domů</li>
          <li>Speciální objekty k inženýrským stavbám</li>
        </ul>
        <h4>Projektová, zeměměřická a inženýrská činnost</h4>
        <img src={projektstavby} alt="" />
        <ul>
          <li>Vypracování studie</li>
          <li>Vypracování projektové dokumentace pro územní souhlas</li>
          <li>Vypracování projektové dokumentace pro územní řízení</li>
          <li>Vypracování projektové dokumentace pro ohlášení</li>
          <li>Vypracování projektové dokumentace pro stavební povolení</li>
          <li>Vypracování projektové dokumentace pro provedení stavby</li>
        </ul>
        <h4>Speciální stavby</h4>
        <img className="special-stavby" src={specialstavby} alt="" />
        <ul>
          <li>Hřbitovy</li>
          <li>Kotelny</li>
          <li>Speciální zámečnické konstrukce (bárky, zámečnické vestavby, oplocení, zábradlí)</li>
          <li>Úpravy vodních toků</li>
          <li>Čerpací stanice</li>
          <li>Výměníkové stanice</li>
          <li>Teplovody</li>
          <li>Biotopy</li>
          <li>Sportovní stavby</li>
          <li>Vodojemy</li>
          <li>Mostky</li>
          <li>Dětská hřiště</li>
        </ul>
        <h5>Inženýrská činnost</h5>
        <ul>
          <li>Zajištění rozhodnutí o umístění stavby</li>
          <li>Zajištění ohlášení staveb</li>
          <li>Zajištění stavebního povolení na stavby</li>
          <li>Zajištění technického dozoru investora</li>
          <li>Zajištění DIO, DIR</li>
          <li>Zajištění smluvních vztahů o věcných břemenech</li>
        </ul>
        <h5>Zeměměřická činnost</h5>
        <ul>
          <li>Vytyčení staveb</li>
          <li>Zaměření skutečného provedení staveb</li>
          <li>Zaměření polohopisu a výškopisu</li>
          <li>Stavební zaměření</li>
          <li>Zpracování věcných břemen pro katastr nemovitostí</li>
          <li>Zpracování Geometrických plánů</li>
        </ul>
        </div>
      </div>
    </section>
  </div>
}

export default Spolecnost