import "./Certifikaty.css"
import { Helmet } from "react-helmet";
import certifikat1 from "../img/2925_001.png"
import certifikat2 from "../img/politika_jakosti.png"

const Certifikaty = () => {

    return <div>
        <Helmet>
<title>Certifikáty</title>
<meta name="title" content="EKIS - Certifikáty"/>
<meta name="description" content=""/>
<meta property="og:type" content="website"/>
<meta property="og:url" content="http://ekis-as.cz/certifikaty"/>
<meta property="og:title" content="EKIS - Certifikáty"/>
<meta property="og:description" content=""/>
<meta property="og:image" content=""/>
        </Helmet>
        <section id="certifikaty">
            <div className="main-items-certifikaty">
                <div className="h2h3-certifikaty">
                    <h2>Certifikáty</h2>
                    </div>
                    <div className="certifikaty">
                        <p>Provádění pozemních a inženýrských staveb, činnost prováděná hornickým způsobem, zeměměřická činnost, projektová činnost ve výstavbě a související inženýrská činnost.</p>
                        <img src={certifikat1} alt="" />
                    </div>
                    <br />
                    <br />
                    <div className="certifikaty">
                        <p>Politika jakosti</p>
                        <img src={certifikat2} alt="" />
                    </div>
            </div>
        </section>
    </div>
}
export default Certifikaty