import { React } from "react";
import { Link } from "react-router-dom";
import "./Projekty.css"
import ImageGallery from 'react-image-gallery';

const images1 = [
    {
        original: 'https://i.imgur.com/zcY7G10.jpg',
        thumbnail: 'https://i.imgur.com/zcY7G10.jpg',
    },
    {
        original: 'https://i.imgur.com/iPgOZag.jpg',
        thumbnail: 'https://i.imgur.com/iPgOZag.jpg',
    },
    {
        original: 'https://i.imgur.com/Ov5asdF.jpg',
        thumbnail: 'https://i.imgur.com/Ov5asdF.jpg',
    },
    {
        original: 'https://i.imgur.com/quqeg2N.jpg',
        thumbnail: 'https://i.imgur.com/quqeg2N.jpg',
    },
    {
        original: 'https://i.imgur.com/aPs0r2I.jpg',
        thumbnail: 'https://i.imgur.com/aPs0r2I.jpg',
    },
    {
        original: 'https://i.imgur.com/KAPGapL.jpg',
        thumbnail: 'https://i.imgur.com/KAPGapL.jpg',
    },
    {
        original: 'https://i.imgur.com/KVC1Sxn.jpg',
        thumbnail: 'https://i.imgur.com/KVC1Sxn.jpg',
    },
];

const images2 = [
    {
        original: 'https://i.imgur.com/WaopdF9.jpg',
        thumbnail: 'https://i.imgur.com/WaopdF9.jpg',
    },
    {
        original: 'https://i.imgur.com/v8VXZbU.jpg',
        thumbnail: 'https://i.imgur.com/v8VXZbU.jpg',
    },
    {
        original: 'https://i.imgur.com/4DPgLNM.jpg',
        thumbnail: 'https://i.imgur.com/4DPgLNM.jpg',
    },
    {
        original: 'https://i.imgur.com/k77GKKS.jpg',
        thumbnail: 'https://i.imgur.com/k77GKKS.jpg',
    },
    {
        original: 'https://i.imgur.com/QTMTwD3.jpg',
        thumbnail: 'https://i.imgur.com/QTMTwD3.jpg',
    },
    {
        original: 'https://i.imgur.com/X2rSfV2.jpg',
        thumbnail: 'https://i.imgur.com/X2rSfV2.jpg',
    },
    {
        original: 'https://i.imgur.com/wVhGx99.jpg',
        thumbnail: 'https://i.imgur.com/wVhGx99.jpg',
    },
    {
        original: 'https://i.imgur.com/w6uvexh.jpg',
        thumbnail: 'https://i.imgur.com/w6uvexh.jpg',
    },
    {
        original: 'https://i.imgur.com/n1oC97N.jpg',
        thumbnail: 'https://i.imgur.com/n1oC97N.jpg',
    },
    {
        original: 'https://i.imgur.com/xXzVrh7.jpg',
        thumbnail: 'https://i.imgur.com/xXzVrh7.jpg',
    },
    {
        original: 'https://i.imgur.com/PWHQ1hH.jpg',
        thumbnail: 'https://i.imgur.com/PWHQ1hH.jpg',
    },
];

const images3 = [
    {
        original: 'https://i.imgur.com/s9Gg1fx.jpg',
        thumbnail: 'https://i.imgur.com/s9Gg1fx.jpg',
    },
    {
        original: 'https://i.imgur.com/NYZZ1N5.jpg',
        thumbnail: 'https://i.imgur.com/NYZZ1N5.jpg',
    },
    {
        original: 'https://i.imgur.com/r91xfEL.jpg',
        thumbnail: 'https://i.imgur.com/r91xfEL.jpg',
    },
    {
        original: 'https://i.imgur.com/RQlo82p.jpg',
        thumbnail: 'https://i.imgur.com/RQlo82p.jpg',
    },
    {
        original: 'https://i.imgur.com/2PZyRbZ.jpg',
        thumbnail: 'https://i.imgur.com/2PZyRbZ.jpg',
    },
    {
        original: 'https://i.imgur.com/IHCqdUx.jpg',
        thumbnail: 'https://i.imgur.com/IHCqdUx.jpg',
    },
];

const images4 = [
    {
        original: 'https://i.imgur.com/f1B9haq.jpg',
        thumbnail: 'https://i.imgur.com/f1B9haq.jpg',
    },
];

const Projekty6 = () => {
    return <div>
        <section id="section-1-pro-6">
            <div className="pozemni">
                <div className="nadpis-pozemni">
                    <h2>Reference - Projekty</h2>
                </div>
                <div className="picovinky-veci">
                    <h3>Rekonstrukce činžovního domu ulice Římská-Praha 2</h3>
                    <ul><li>Projekt pro stavební povolení včetně inženýrské činnosti</li>
                        <li>Vestavba dvou bytových jednotek do podkroví domu</li>
                        <li>Sanace dřevěné konstrukce krovu</li>
                        <li>Výměna střešní krytiny v historickém stylu</li>
                        <li>Výměna klempířských konstrukcí</li>
                        <li>Sanace nadstřešních objektů (komínů, lávek a zámečnických konstrukcí)</li>
                        <li>Rekonstrukce historické dvorní fasády</li>
                        <li>Sanace stávajících balkónů</li></ul>
                </div>
                <ImageGallery showPlayButton={false} showFullscreenButton={false} showBullets={true} items={images1} />
            </div>
        </section>
        <section id="section-2-pro-6">
            <div className="pozemni">
                <div className="picovinky-veci">
                    <h3>Rodinný dům Nové Jirny</h3>
                    <ul><li>Projekt pro stavební povolení, stavební dozorování</li>
                        <li>Nepodsklepený rodinný dům 5+1 včetně garáže umístěn na rovinném pozemku</li>
                        <li>Zděný z cihelných bloků, zateplený kontaktním pláštěm tl. 120mm, štíty- provětrávaný plášť - obklad palubkami</li>
                        <li>Střešní konstrukce dřevěná</li>
                        <li>Střešní krytina pálená taška-bobrovka</li>
                        <li>Okna dřevěná s izolačním dvojsklem</li>
                        <li>Obklad soklu z cihelných pásků </li></ul>
                </div>
                <ImageGallery showPlayButton={false} showFullscreenButton={false} showBullets={true} items={images2} />
            </div>
        </section>
        <section id="section-3-pro-6">
            <div className="pozemni">
                <div className="picovinky-veci">
                    <h3>Rodinný dům Dřevčice</h3>
                    <ul><li>Projekt pro stavební povolení</li>
                        <li>Nepodsklepený rodinný dům 5+1 včetně garáže</li>
                        <li>Zděný, zateplený kontaktním pláštěm tl.100mm</li>
                        <li>Střešní konstrukce dřevěná, střešní krytina betonová</li>
                        <li>Okna dřevěná s izolačním dvojsklem</li>
                        <li>Objekt vytápěn plynovým kotlem kombinovaným se zásobníkem teplé užitkové vody</li>
                        <li>Dům připojen na veškeré inženýrské sítě</li>
                        <li>Venkovní zpevněné plochy ze zámkové dlažby</li>
                        <li>Oplocení z betonových štípaných bloků</li></ul>
                </div>
                <ImageGallery showPlayButton={false} showFullscreenButton={false} showBullets={true} items={images3} />
            </div>
        </section>
        <section id="section-4-pro-6">
            <div className="pozemni">
                <div className="picovinky-veci">
                    <h3>Boels ČR spol. s r.o.-rekonstrukce skladové haly v Hloubětíně</h3>
                    <ul><li>projekt pro stavební povolení včetně inženýrské činnosti</li>
                        <li>Stavební úpravy skladové haly- vestavba myčky pro mechanizaci včetně technologie čištění odpadních vod</li>
                        <li>Vestavba půjčovny mechanizace včetně zázemí</li>
                        <li>Administrativní vestavba k půjčovně</li>
                        <li>Venkovní plochy pro parkování mechanizace</li>
                        <li>Zajištění vodoprávních povolení pro čištění odpadních vod</li>
                        <li>Zajištění statických posudků stávajících konstrukcí</li>
                        <li>Zajištění požárně bezpečnostního řešení</li></ul>
                </div>
                <ImageGallery showPlayButton={false} showFullscreenButton={false} showBullets={true} items={images4} />
            </div>
        </section>
        <section id="strankovani">
            <div class="center">
                <div class="pagination-relas">
                    <Link to="../reference/projekty">1</Link>
                    <Link to="../reference/projekty-2">2</Link>
                    <Link to="../reference/projekty-3">3</Link>
                    <Link to="../reference/projekty-4">4</Link>
                    <Link to="../reference/projekty-5">5</Link>
                    <Link className="active">6</Link>
                </div>
            </div>
        </section>
    </div>
}

export default Projekty6