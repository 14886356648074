import { React } from "react";
import { Link } from "react-router-dom";
import "./Projekty.css"
import ImageGallery from 'react-image-gallery';

const images1 = [
    {
        original: 'https://i.imgur.com/wSu5o7G.jpg',
        thumbnail: 'https://i.imgur.com/wSu5o7G.jpg',
    },
    {
        original: 'https://i.imgur.com/wH3JXPx.jpg',
        thumbnail: 'https://i.imgur.com/wH3JXPx.jpg',
    },
    {
        original: 'https://i.imgur.com/Yz4DzGb.jpg',
        thumbnail: 'https://i.imgur.com/Yz4DzGb.jpg',
    },
    {
        original: 'https://i.imgur.com/Lkvy176.jpg',
        thumbnail: 'https://i.imgur.com/Lkvy176.jpg',
    },
    {
        original: 'https://i.imgur.com/O5QiuLV.jpg',
        thumbnail: 'https://i.imgur.com/O5QiuLV.jpg',
    },
    {
        original: 'https://i.imgur.com/vdKlp9A.jpg',
        thumbnail: 'https://i.imgur.com/vdKlp9A.jpg',
    },
    {
        original: 'https://i.imgur.com/7Z0rky0.jpg',
        thumbnail: 'https://i.imgur.com/7Z0rky0.jpg',
    },
];

const images2 = [
    {
        original: 'https://i.imgur.com/oBmBFAC.jpg',
        thumbnail: 'https://i.imgur.com/oBmBFAC.jpg',
    },
    {
        original: 'https://i.imgur.com/S0teBEN.jpg',
        thumbnail: 'https://i.imgur.com/S0teBEN.jpg',
    },
    {
        original: 'https://i.imgur.com/IV6wfXH.jpg',
        thumbnail: 'https://i.imgur.com/IV6wfXH.jpg',
    },
    {
        original: 'https://i.imgur.com/9lLR26P.jpg',
        thumbnail: 'https://i.imgur.com/9lLR26P.jpg',
    },
    {
        original: 'https://i.imgur.com/jZMWSCw.jpg',
        thumbnail: 'https://i.imgur.com/jZMWSCw.jpg',
    },
    {
        original: 'https://i.imgur.com/T3PVxtb.jpg',
        thumbnail: 'https://i.imgur.com/T3PVxtb.jpg',
    },
    {
        original: 'https://i.imgur.com/ysKFmn5.jpg',
        thumbnail: 'https://i.imgur.com/ysKFmn5.jpg',
    },
    {
        original: 'https://i.imgur.com/UNuiSWo.jpg',
        thumbnail: 'https://i.imgur.com/UNuiSWo.jpg',
    },
    {
        original: 'https://i.imgur.com/mKgqRNf.jpg',
        thumbnail: 'https://i.imgur.com/mKgqRNf.jpg',
    },
];

const images3 = [
    {
        original: 'https://i.imgur.com/vfZ4Ech.jpg',
        thumbnail: 'https://i.imgur.com/vfZ4Ech.jpg',
    },
    {
        original: 'https://i.imgur.com/dgI5xDn.jpg',
        thumbnail: 'https://i.imgur.com/dgI5xDn.jpg',
    },
    {
        original: 'https://i.imgur.com/3vexfCd.jpg',
        thumbnail: 'https://i.imgur.com/3vexfCd.jpg',
    },
];

const images4 = [
    {
        original: 'https://i.imgur.com/5B76qjq.jpg',
        thumbnail: 'https://i.imgur.com/5B76qjq.jpg',
    },
    {
        original: 'https://i.imgur.com/CCykul4.jpg',
        thumbnail: 'https://i.imgur.com/CCykul4.jpg',
    },
    {
        original: 'https://i.imgur.com/2H6STY0.jpg',
        thumbnail: 'https://i.imgur.com/2H6STY0.jpg',
    },
    {
        original: 'https://i.imgur.com/l9Ui7sw.jpg',
        thumbnail: 'https://i.imgur.com/l9Ui7sw.jpg',
    },
    {
        original: 'https://i.imgur.com/MSPgeZV.jpg',
        thumbnail: 'https://i.imgur.com/MSPgeZV.jpg',
    },
    {
        original: 'https://i.imgur.com/4eGCFQb.jpg',
        thumbnail: 'https://i.imgur.com/4eGCFQb.jpg',
    },
    {
        original: 'https://i.imgur.com/CJ2ZNX6.jpg',
        thumbnail: 'https://i.imgur.com/CJ2ZNX6.jpg',
    },
    {
        original: 'https://i.imgur.com/ADrQc6b.jpg',
        thumbnail: 'https://i.imgur.com/ADrQc6b.jpg',
    },
];


const images5 = [
    {
        original: 'https://i.imgur.com/ZrWReKE.jpg',
        thumbnail: 'https://i.imgur.com/ZrWReKE.jpg',
    },
    {
        original: 'https://i.imgur.com/oj1HW0c.jpg',
        thumbnail: 'https://i.imgur.com/oj1HW0c.jpg',
    },
    {
        original: 'https://i.imgur.com/BWFzBs2.jpg',
        thumbnail: 'https://i.imgur.com/BWFzBs2.jpg',
    },
    {
        original: 'https://i.imgur.com/UMQPLPw.jpg',
        thumbnail: 'https://i.imgur.com/UMQPLPw.jpg',
    },
    {
        original: 'https://i.imgur.com/FrojK6R.jpg',
        thumbnail: 'https://i.imgur.com/FrojK6R.jpg',
    },
    {
        original: 'https://i.imgur.com/4N6iqdv.jpg',
        thumbnail: 'https://i.imgur.com/4N6iqdv.jpg',
    },
    {
        original: 'https://i.imgur.com/qTnBeNs.jpg',
        thumbnail: 'https://i.imgur.com/qTnBeNs.jpg',
    },
    {
        original: 'https://i.imgur.com/eoH0RE3.jpg',
        thumbnail: 'https://i.imgur.com/eoH0RE3.jpg',
    },
    {
        original: 'https://i.imgur.com/J7vbspV.jpg',
        thumbnail: 'https://i.imgur.com/J7vbspV.jpg',
    },
    {
        original: 'https://i.imgur.com/bO1SXCZ.jpg',
        thumbnail: 'https://i.imgur.com/bO1SXCZ.jpg',
    },
    {
        original: 'https://i.imgur.com/XfiSXCA.jpg',
        thumbnail: 'https://i.imgur.com/XfiSXCA.jpg',
    },
    {
        original: 'https://i.imgur.com/yYFCg20.jpg',
        thumbnail: 'https://i.imgur.com/yYFCg20.jpg',
    },
    {
        original: 'https://i.imgur.com/jjpx79f.jpg',
        thumbnail: 'https://i.imgur.com/jjpx79f.jpg',
    },
    {
        original: 'https://i.imgur.com/WggNTqf.jpg',
        thumbnail: 'https://i.imgur.com/WggNTqf.jpg',
    },
];

const Projekty = () => {
    return <div>
        <section id="section-1">
            <div className="pozemni">
                <div className="nadpis-pozemni">
                    <h2>Reference - Projekty</h2>
                </div>
                <div className="picovinky-veci">
                    <h3>Rekonstrukce komunikací v Řevnicích</h3>
                    <ul>
                        <li>Projektová dokumentace pro stavební povolení</li>
                        <li>Rozpočet</li>
                    </ul>
                </div>
                <ImageGallery showPlayButton={false} showFullscreenButton={false} showBullets={true} items={images1} />
            </div>
        </section>
        <section id="section-2">
            <div className="pozemni">
                <div className="picovinky-veci">
                    <h3>Rekonstrukce komunikací ve Všenorech</h3>
                    <ul><li>Projektová dokumentace pro stavební povolení </li>
                        <li>Inženýrská činnost</li>
                        <li>Rozpočet</li>
                        <li>Autorský dozor</li></ul>
                </div>
                <ImageGallery showPlayButton={false} showFullscreenButton={false} showBullets={true} items={images2} />
            </div>
        </section>
        <section id="section-3">
            <div className="pozemni">
                <div className="picovinky-veci">
                    <h3>Rozšíření areálu firmy EKIS - novostavba haly</h3>
                    <ul><li>Přípravné práce</li>
                        <li>Studie</li>
                    </ul>
                </div>
                <ImageGallery showPlayButton={false} showFullscreenButton={false} showBullets={true} items={images3} />
            </div>
        </section>
        <section id="section-4">
            <div className="pozemni">
                <div className="picovinky-veci">
                    <h3>Místní komunikace Hlavenec</h3>
                    <p>Projektová dokumentace a inženýrská činnost (stavební povolení).</p>
                </div>
                <ImageGallery showPlayButton={false} showFullscreenButton={false} showBullets={true} items={images4} />
            </div>
        </section>
        <section id="section-5-pro-1">
            <div className="pozemni">
                <div className="picovinky-veci">
                    <h3>Radonice - rekonstrukce komunikace a dešťové kanalizace</h3>
                    <p>Projektová dokumentace vč. inženýrské činnosti (stavební povolení) na rekonstrukci stávajícího povrchu <br />
komunikace ul. Růžičkova s výstavbou nové dešťové kanalizace s výustním objektem
</p>
                </div>
                <ImageGallery showPlayButton={false} showFullscreenButton={false} showBullets={true} items={images5} />
            </div>
        </section>
        <section id="strankovani">
            <div class="center">
                <div class="pagination-relas">
                    <Link className="active">1</Link>
                    <Link to="../reference/projekty-2">2</Link>
                    <Link to="../reference/projekty-3">3</Link>
                    <Link to="../reference/projekty-4">4</Link>
                    <Link to="../reference/projekty-5">5</Link>
                    <Link to="../reference/projekty-6">6</Link>
                </div>
            </div>
        </section>
    </div>
}

export default Projekty