import logo from "./img/logo1.png"
import { FaFacebookF } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { Outlet, NavLink, Link} from "react-router-dom";
import { slide as Menu } from 'react-burger-menu'
import { useState } from "react";
import { useLocation } from "react-router-dom";
const Navbar = () => {

    const [menu, setMenu] = useState(false)

    function closeMenu() {
        setMenu(false)
    }

    function openMenu() {
        setMenu(true)
    }

    function handleStateChange() {
        setMenu(menu)
    }

    const [colorChange, setColorchange] = useState(false);
    const changeNavbarColor = () => {
        if (window.scrollY >= 80) {
            setColorchange(true);
        }
        else {
            setColorchange(false);
        }
    };
    window.addEventListener('scroll', changeNavbarColor);

    return <div>
        <header style={{
            background: useLocation().pathname !== "/" ? "#349f00" : "", position: useLocation().pathname!== "/" ? "sticky" : "fixed"}} id="header" className={colorChange ? 'navbar colorChange' : 'navbar'}>
                <div className="header-items"></div>
            <div className="img-logo">
                <NavLink to="/"><img src={logo} alt="logo" className="logo" /></NavLink>
            </div>
            <div className="hamburger-menu">
                <Menu
                    left
                    width={'250px'}
                    isOpen={menu}
                    onClose={closeMenu}
                    onOpen={openMenu}
                    onStateChange={handleStateChange}>
                    <div className="listItems">
                        <Link onClick={closeMenu} to="/spolecnost" className="burger-items">O společnosti</Link>
                        <Link onClick={closeMenu} to="/certifikaty" className="burger-items">Certifikáty</Link>
                        <Link onClick={closeMenu} to="/reference" className="burger-items">Reference</Link>
                        <Link onClick={closeMenu} to="/poradenstvi" className="burger-items">Poradenství</Link>
                        <Link onClick={closeMenu} to="/kariera" className="burger-items">Kariéra</Link>
                        <Link onClick={closeMenu} to="/kontakt" className="burger-items">Kontakt</Link>
                    </div>
                </Menu>
            </div>
            <nav>
                <ul>
                    <div className="items-nav">
                        <NavLink to="/spolecnost" className={({ isActive }) => (isActive ? "link-active" : "link")}><li>O společnosti</li></NavLink>
                        <NavLink to="/certifikaty" className={({ isActive }) => (isActive ? "link-active" : "link")}><li>Certifikáty</li></NavLink>
                        <NavLink to="/reference" className={({ isActive }) => (isActive ? "link-active" : "link")}><li>Reference</li></NavLink>
                        <NavLink to="/poradenstvi" className={({ isActive }) => (isActive ? "link-active" : "link")}><li>Poradenství</li></NavLink>
                        <NavLink to="/kariera" className={({ isActive }) => (isActive ? "link-active" : "link")}><li>Kariéra</li></NavLink>
                        <NavLink to="/kontakt" className={({ isActive }) => (isActive ? "link-active" : "link")}><li>Kontakt</li></NavLink>
                    </div>
                </ul>
            </nav>
            <div className="ikonky-div">
                <Link to="https://www.facebook.com/profile.php?id=100063520063630" target="_blank" rel="noreferrer"><FaFacebookF className="ikonky facebook-ikonka" /></Link>
                <Link to="https://www.instagram.com/ekis_stavby_pro_vas/" target="_blank" rel="noreferrer"><FaInstagram className="ikonky instagram-ikonka" /></Link>
            </div>
        </header>
        <Outlet />
    </div>
}

export default Navbar