import { React } from "react";
import { Link } from "react-router-dom";
import "./Pozemni-stavby-3.css"
import ImageGallery from 'react-image-gallery';

const images1 = [
    {
        original: 'https://i.imgur.com/CMyhR2M.jpg',
        thumbnail: 'https://i.imgur.com/CMyhR2M.jpg',
    },
    {
        original: 'https://i.imgur.com/6jFIDsR.jpg',
        thumbnail: 'https://i.imgur.com/6jFIDsR.jpg',
    },
    {
        original: 'https://i.imgur.com/GJko6v6.jpg',
        thumbnail: 'https://i.imgur.com/GJko6v6.jpg',
    },
    {
        original: 'https://i.imgur.com/cV71x8L.jpg',
        thumbnail: 'https://i.imgur.com/cV71x8L.jpg',
    },
    {
        original: 'https://i.imgur.com/bh6wAVM.jpg',
        thumbnail: 'https://i.imgur.com/bh6wAVM.jpg',
    },
    {
        original: 'https://i.imgur.com/D3yhMte.jpg',
        thumbnail: 'https://i.imgur.com/D3yhMte.jpg',
    },
];

const images2 = [
    {
        original: 'https://i.imgur.com/7hAqT1Q.jpg',
        thumbnail: 'https://i.imgur.com/7hAqT1Q.jpg',
    },
    {
        original: 'https://i.imgur.com/xFRVqUT.jpg',
        thumbnail: 'https://i.imgur.com/xFRVqUT.jpg',
    },
    {
        original: 'https://i.imgur.com/YHD65gv.jpg',
        thumbnail: 'https://i.imgur.com/YHD65gv.jpg',
    },
];

const images3 = [
    {
        original: 'https://i.imgur.com/ou3Y09N.jpg',
        thumbnail: 'https://i.imgur.com/ou3Y09N.jpg',
    },
    {
        original: 'https://i.imgur.com/uVINcb2.jpg',
        thumbnail: 'https://i.imgur.com/uVINcb2.jpg',
    },
    {
        original: 'https://i.imgur.com/d1mTMwV.jpg',
        thumbnail: 'https://i.imgur.com/d1mTMwV.jpg',
    },
    {
        original: 'https://i.imgur.com/y0jcNSo.jpg',
        thumbnail: 'https://i.imgur.com/y0jcNSo.jpg',
    },
    {
        original: 'https://i.imgur.com/wxtUS5G.jpg',
        thumbnail: 'https://i.imgur.com/wxtUS5G.jpg',
    },
    {
        original: 'https://i.imgur.com/z8nnH1v.jpg',
        thumbnail: 'https://i.imgur.com/z8nnH1v.jpg',
    },
    {
        original: 'https://i.imgur.com/3PEv0lZ.jpg',
        thumbnail: 'https://i.imgur.com/3PEv0lZ.jpg',
    },
    {
        original: 'https://i.imgur.com/BIR08E3.jpg',
        thumbnail: 'https://i.imgur.com/BIR08E3.jpg',
    },
    {
        original: 'https://i.imgur.com/8QCWdSN.jpg',
        thumbnail: 'https://i.imgur.com/8QCWdSN.jpg',
    },
];

const images4 = [
    {
        original: 'https://i.imgur.com/NhMPrAS.jpg',
        thumbnail: 'https://i.imgur.com/NhMPrAS.jpg',
    },
    {
        original: 'https://i.imgur.com/GmfNG0K.jpg',
        thumbnail: 'https://i.imgur.com/GmfNG0K.jpg',
    },
    {
        original: 'https://i.imgur.com/KJA5W7S.jpg',
        thumbnail: 'https://i.imgur.com/KJA5W7S.jpg',
    },
    {
        original: 'https://i.imgur.com/8oGqo87.jpg',
        thumbnail: 'https://i.imgur.com/8oGqo87.jpg',
    },
    {
        original: 'https://i.imgur.com/ruSMcSR.jpg',
        thumbnail: 'https://i.imgur.com/ruSMcSR.jpg',
    },
    {
        original: 'https://i.imgur.com/V1q7nb6.jpg',
        thumbnail: 'https://i.imgur.com/V1q7nb6.jpg',
    },
    {
        original: 'https://i.imgur.com/KXUek94.jpg',
        thumbnail: 'https://i.imgur.com/KXUek94.jpg',
    },
    {
        original: 'https://i.imgur.com/sgAunn3.jpg',
        thumbnail: 'https://i.imgur.com/sgAunn3.jpg',
    },
    {
        original: 'https://i.imgur.com/My6JFwn.jpg',
        thumbnail: 'https://i.imgur.com/My6JFwn.jpg',
    },
    {
        original: 'https://i.imgur.com/m2r2zXq.jpg',
        thumbnail: 'https://i.imgur.com/m2r2zXq.jpg',
    },
];


const images5 = [
    {
        original: 'https://i.imgur.com/PKhcMzz.jpg',
        thumbnail: 'https://i.imgur.com/PKhcMzz.jpg',
    },
    {
        original: 'https://i.imgur.com/wS3keQ8.jpg',
        thumbnail: 'https://i.imgur.com/wS3keQ8.jpg',
    },
];

const PozemniStavby3 = () => {
    return <div>
        <section id="section-1">
            <div className="pozemni">
                <div className="nadpis-pozemni">
                    <h2>Reference - Pozemní stavby</h2>
                </div>
                <div className="picovinky-veci">
                    <h3>Infocentrum Horní Počernice</h3>
                </div>
                <ImageGallery showPlayButton={false} showFullscreenButton={false} showBullets={true} items={images1} />
            </div>
        </section>
        <section id="section-2">
            <div className="pozemni">
                <div className="picovinky-veci">
                    <h3>Rekonstrukce domu s pečovatelskou službou v Horních Počernicích</h3>
                </div>
                <ImageGallery showPlayButton={false} showFullscreenButton={false} showBullets={true} items={images2} />
            </div>
        </section>
        <section id="section-3">
            <div className="pozemni">
                <div className="picovinky-veci">
                    <h3>Rekonstrukce činžovního domu v ulici Římská, Praha 2</h3>
                </div>
                <ImageGallery showPlayButton={false} showFullscreenButton={false} showBullets={true} items={images3} />
            </div>
        </section>
        <section id="section-4">
            <div className="pozemni">
                <div className="picovinky-veci">
                    <h3>Novostavba areálu společnosti Rothlehner</h3>
                </div>
                <ImageGallery showPlayButton={false} showFullscreenButton={false} showBullets={true} items={images4} />
            </div>
        </section>
        <section id="section-5-2">
            <div className="pozemni">
                <div className="picovinky-veci">
                    <h3>Rekonstrukce provozního pavilónu Gymnázia Českolipská</h3>
                </div>
                <ImageGallery showPlayButton={false} showFullscreenButton={false} showBullets={true} items={images5} />
            </div>
        </section>
        <section id="strankovani">
            <div class="center">
                <div class="pagination-relas">
                    <Link to="../reference/pozemni-stavby">1</Link>
                    <Link to="../reference/pozemni-stavby-2">2</Link>
                    <Link className="active">3</Link>
                    <Link to="../reference/pozemni-stavby-4">4</Link>
                </div>
            </div>
        </section>
    </div>
}

export default PozemniStavby3