import { React } from "react";
import { Link } from "react-router-dom";
import "./Projekty.css"
import ImageGallery from 'react-image-gallery';

const images1 = [
    {
        original: 'https://i.imgur.com/yZEkXxo.jpg',
        thumbnail: 'https://i.imgur.com/yZEkXxo.jpg',
    },
    {
        original: 'https://i.imgur.com/nAIXjAD.jpg',
        thumbnail: 'https://i.imgur.com/nAIXjAD.jpg',
    },
    {
        original: 'https://i.imgur.com/zu1kzS9.jpg',
        thumbnail: 'https://i.imgur.com/zu1kzS9.jpg',
    },
    {
        original: 'https://i.imgur.com/RhEIwTU.jpg',
        thumbnail: 'https://i.imgur.com/RhEIwTU.jpg',
    },
];

const images2 = [
    {
        original: 'https://i.imgur.com/SsdnMla.jpg',
        thumbnail: 'https://i.imgur.com/SsdnMla.jpg',
    },
    {
        original: 'https://i.imgur.com/6NB5max.jpg',
        thumbnail: 'https://i.imgur.com/6NB5max.jpg',
    },
    {
        original: 'https://i.imgur.com/oLIfZCV.jpg',
        thumbnail: 'https://i.imgur.com/oLIfZCV.jpg',
    },
    {
        original: 'https://i.imgur.com/VfEgi7L.jpg',
        thumbnail: 'https://i.imgur.com/VfEgi7L.jpg',
    },
];

const images3 = [
    {
        original: 'https://i.imgur.com/S9TCypn.jpg',
        thumbnail: 'https://i.imgur.com/S9TCypn.jpg',
    },
    {
        original: 'https://i.imgur.com/3seKVsJ.jpg',
        thumbnail: 'https://i.imgur.com/3seKVsJ.jpg',
    },
    {
        original: 'https://i.imgur.com/UIokm94.jpg',
        thumbnail: 'https://i.imgur.com/UIokm94.jpg',
    },
    {
        original: 'https://i.imgur.com/P5WsuAl.jpg',
        thumbnail: 'https://i.imgur.com/P5WsuAl.jpg',
    },
];

const images4 = [
    {
        original: 'https://i.imgur.com/Veo0LAY.jpg',
        thumbnail: 'https://i.imgur.com/Veo0LAY.jpg',
    },
    {
        original: 'https://i.imgur.com/t0SKR3E.jpg',
        thumbnail: 'https://i.imgur.com/t0SKR3E.jpg',
    },
    {
        original: 'https://i.imgur.com/xA1ZGrF.jpg',
        thumbnail: 'https://i.imgur.com/xA1ZGrF.jpg',
    },
    {
        original: 'https://i.imgur.com/UkKUM7A.jpg',
        thumbnail: 'https://i.imgur.com/UkKUM7A.jpg',
    },
    {
        original: 'https://i.imgur.com/zVAiPu4.jpg',
        thumbnail: 'https://i.imgur.com/zVAiPu4.jpg',
    },
    {
        original: 'https://i.imgur.com/bgiOPJm.jpg',
        thumbnail: 'https://i.imgur.com/bgiOPJm.jpg',
    },
    {
        original: 'https://i.imgur.com/cUSGi0i.jpg',
        thumbnail: 'https://i.imgur.com/cUSGi0i.jpg',
    },
    {
        original: 'https://i.imgur.com/IbjUmBo.jpg',
        thumbnail: 'https://i.imgur.com/IbjUmBo.jpg',
    },
    {
        original: 'https://i.imgur.com/wj41wPe.jpg',
        thumbnail: 'https://i.imgur.com/wj41wPe.jpg',
    },
    {
        original: 'https://i.imgur.com/XgKOtQN.jpg',
        thumbnail: 'https://i.imgur.com/XgKOtQN.jpg',
    },
    {
        original: 'https://i.imgur.com/63NfFmY.jpg',
        thumbnail: 'https://i.imgur.com/63NfFmY.jpg',
    },
    {
        original: 'https://i.imgur.com/EZ6GIzu.jpg',
        thumbnail: 'https://i.imgur.com/EZ6GIzu.jpg',
    },
    {
        original: 'https://i.imgur.com/i0PxbQN.jpg',
        thumbnail: 'https://i.imgur.com/i0PxbQN.jpg',
    },
];


const images5 = [
    {
        original: 'https://i.imgur.com/QPnsdS9.jpg',
        thumbnail: 'https://i.imgur.com/QPnsdS9.jpg',
    },
    {
        original: 'https://i.imgur.com/DlvJyXq.jpg',
        thumbnail: 'https://i.imgur.com/DlvJyXq.jpg',
    },
    {
        original: 'https://i.imgur.com/xIJojQ1.jpg',
        thumbnail: 'https://i.imgur.com/xIJojQ1.jpg',
    },
    {
        original: 'https://i.imgur.com/ugEkPLb.jpg',
        thumbnail: 'https://i.imgur.com/ugEkPLb.jpg',
    },
];

const Projekty4 = () => {
    return <div>
        <section id="section-1">
            <div className="pozemni">
                <div className="nadpis-pozemni">
                    <h2>Reference - Projekty</h2>
                </div>
                <div className="picovinky-veci">
                    <h3>Novostavba RD - Toušeň</h3>
                    <ul><li>Studie</li>
                        <li>Projektová dokumentace pro stavební povolení</li>
                        <li>Inženýrská činnost</li></ul>
                </div>
                <ImageGallery showPlayButton={false} showFullscreenButton={false} showBullets={true} items={images1} />
            </div>
        </section>
        <section id="section-2">
            <div className="pozemni">
                <div className="picovinky-veci">
                    <h3>Rodinný dům 01 - Radonice u Prahy</h3>
                    <ul><li>Studie</li>
                        <li>Projektová dokumentace pro ohlášení stavby</li>
                        <li>Inženýrská činnost</li>
                        <li>Rozpočet</li></ul>
                </div>
                <ImageGallery showPlayButton={false} showFullscreenButton={false} showBullets={true} items={images2} />
            </div>
        </section>
        <section id="section-3">
            <div className="pozemni">
                <div className="picovinky-veci">
                    <h3>Rodinný dům v Radonicích u Prahy</h3>
                    <ul><li>Studie rodinného domu v Radonicích</li>
                        <li>Projektová dokumentace pro stavební povolení</li>
                        <li>Inženýrská činnost</li></ul>
                </div>
                <ImageGallery showPlayButton={false} showFullscreenButton={false} showBullets={true} items={images3} />
            </div>
        </section>
        <section id="section-4">
            <div className="pozemni">
                <div className="picovinky-veci">
                    <h3>Revitalizace centra obce Libeň u Libeře</h3>
                    <ul><li>Dešťová kanalizace</li>
                        <li>Komunikace a chodníky</li>
                        <li>Revitalizace břehů rybníku</li>
                        <li>Molo</li>
                        <li>Veřejné osvětlení</li>
                        <li>Mobiliář</li>
                        <li>Sadové úpravy</li>
                        <li>Inženýrská činnost</li></ul>
                </div>
                <ImageGallery showPlayButton={false} showFullscreenButton={false} showBullets={true} items={images4} />
            </div>
        </section>
        <section id="section-5-pro-4">
            <div className="pozemni">
                <div className="picovinky-veci">
                    <h3>Hotel Žamberk</h3>
                    <ul><li>Projekt pro územní řízení, stavební povolení, realizaci včetně inženýrské činnosti</li>
                        <li>Hotel *** pro 50 hostů</li>
                        <li>Podzemní podlaží, 3 nadzemní podlaží a podkroví</li>
                        <li>Kuchyň s kapacitou 150 jídel</li>
                        <li>Restaurace 80 míst</li>
                        <li>Bar 50 míst</li>
                        <li>Bowling 2 dráhy</li>
                        <li>Konferenční sál pro 50 hostů</li>
                        <li>Knihovna</li>
                        <li>Venkovní terasa pro 50 hostů</li>
                        <li>2 tenisové kurty</li>
                        <li>Parkování pro 80 vozidel a 3 autobusy</li>
                        <li>Park před hotelem s altánem </li></ul>
                </div>
                <ImageGallery showPlayButton={false} showFullscreenButton={false} showBullets={true} items={images5} />
            </div>
        </section>
        <section id="strankovani">
            <div class="center">
                <div class="pagination-relas">
                    <Link to="../reference/projekty">1</Link>
                    <Link to="../reference/projekty-2">2</Link>
                    <Link to="../reference/projekty-3">3</Link>
                    <Link className="active">4</Link>
                    <Link to="../reference/projekty-5">5</Link>
                    <Link to="../reference/projekty-6">6</Link>
                </div>
            </div>
        </section>
    </div>
}

export default Projekty4