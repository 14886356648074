import { React } from "react";
import { Link } from "react-router-dom";
import "./InzenyrskeStavby.css"
import ImageGallery from 'react-image-gallery';

const images1 = [
    {
        original: 'https://i.imgur.com/DJugTao.jpg',
        thumbnail: 'https://i.imgur.com/DJugTao.jpg',
    },
    {
        original: 'https://i.imgur.com/On9YnC4.jpg',
        thumbnail: 'https://i.imgur.com/On9YnC4.jpg',
    },
    {
        original: 'https://i.imgur.com/nOBSIRR.jpg',
        thumbnail: 'https://i.imgur.com/nOBSIRR.jpg',
    },
    {
        original: 'https://i.imgur.com/HqX0udQ.jpg',
        thumbnail: 'https://i.imgur.com/HqX0udQ.jpg',
    },
    {
        original: 'https://i.imgur.com/PGVEDoT.jpg',
        thumbnail: 'https://i.imgur.com/PGVEDoT.jpg',
    },
    {
        original: 'https://i.imgur.com/wHjIHny.jpg',
        thumbnail: 'https://i.imgur.com/wHjIHny.jpg',
    },
    {
        original: 'https://i.imgur.com/G9hFiwc.jpg',
        thumbnail: 'https://i.imgur.com/G9hFiwc.jpg',
    },
    {
        original: 'https://i.imgur.com/6svi0EX.jpg',
        thumbnail: 'https://i.imgur.com/6svi0EX.jpg',
    },
    {
        original: 'https://i.imgur.com/v18JrQz.jpg',
        thumbnail: 'https://i.imgur.com/v18JrQz.jpg',
    },
    {
        original: 'https://i.imgur.com/6gQ9tcG.jpg',
        thumbnail: 'https://i.imgur.com/6gQ9tcG.jpg',
    },
    {
        original: 'https://i.imgur.com/YI1eJDE.jpg',
        thumbnail: 'https://i.imgur.com/YI1eJDE.jpg',
    },
];

const images2 = [
    {
        original: 'https://i.imgur.com/vIZN5OI.jpg',
        thumbnail: 'https://i.imgur.com/vIZN5OI.jpg',
    },
    {
        original: 'https://i.imgur.com/VPJ2CIo.jpg',
        thumbnail: 'https://i.imgur.com/VPJ2CIo.jpg',
    },
    {
        original: 'https://i.imgur.com/g13xmzz.jpg',
        thumbnail: 'https://i.imgur.com/g13xmzz.jpg',
    },
    {
        original: 'https://i.imgur.com/krILy1K.jpg',
        thumbnail: 'https://i.imgur.com/krILy1K.jpg',
    },
    {
        original: 'https://i.imgur.com/Rvd0Tzc.jpg',
        thumbnail: 'https://i.imgur.com/Rvd0Tzc.jpg',
    },
    {
        original: 'https://i.imgur.com/9tFRR1P.jpg',
        thumbnail: 'https://i.imgur.com/9tFRR1P.jpg',
    },
    {
        original: 'https://i.imgur.com/CI9RVUC.jpg',
        thumbnail: 'https://i.imgur.com/CI9RVUC.jpg',
    },
    {
        original: 'https://i.imgur.com/MDNHHYN.jpg',
        thumbnail: 'https://i.imgur.com/MDNHHYN.jpg',
    },
    {
        original: 'https://i.imgur.com/aymDK0v.jpg',
        thumbnail: 'https://i.imgur.com/aymDK0v.jpg',
    },
    {
        original: 'https://i.imgur.com/4QnBTxf.jpg',
        thumbnail: 'https://i.imgur.com/4QnBTxf.jpg',
    },
    {
        original: 'https://i.imgur.com/qwTnZ8y.jpg',
        thumbnail: 'https://i.imgur.com/qwTnZ8y.jpg',
    },
];

const images3 = [
    {
        original: 'https://i.imgur.com/PZkEKJV.jpg',
        thumbnail: 'https://i.imgur.com/PZkEKJV.jpg',
    },
    {
        original: 'https://i.imgur.com/Gjh0ISV.jpg',
        thumbnail: 'https://i.imgur.com/Gjh0ISV.jpg',
    },
    {
        original: 'https://i.imgur.com/BIUAXhD.jpg',
        thumbnail: 'https://i.imgur.com/BIUAXhD.jpg',
    },
    {
        original: 'https://i.imgur.com/oiJ7NQo.jpg',
        thumbnail: 'https://i.imgur.com/oiJ7NQo.jpg',
    },
    {
        original: 'https://i.imgur.com/hked2ph.jpg',
        thumbnail: 'https://i.imgur.com/hked2ph.jpg',
    },
    {
        original: 'https://i.imgur.com/K1fxAS7.jpg',
        thumbnail: 'https://i.imgur.com/K1fxAS7.jpg',
    },
    {
        original: 'https://i.imgur.com/mRhXiwG.jpg',
        thumbnail: 'https://i.imgur.com/mRhXiwG.jpg',
    },
    {
        original: 'https://i.imgur.com/gp9tYN3.jpg',
        thumbnail: 'https://i.imgur.com/gp9tYN3.jpg',
    },
    {
        original: 'https://i.imgur.com/HW7M4CF.jpg',
        thumbnail: 'https://i.imgur.com/HW7M4CF.jpg',
    },
    {
        original: 'https://i.imgur.com/Qhew26E.jpg',
        thumbnail: 'https://i.imgur.com/Qhew26E.jpg',
    },
    {
        original: 'https://i.imgur.com/imsNiRQ.jpg',
        thumbnail: 'https://i.imgur.com/imsNiRQ.jpg',
    },
];

const images4 = [
    {
        original: 'https://i.imgur.com/vQtFIYh.jpg',
        thumbnail: 'https://i.imgur.com/vQtFIYh.jpg',
    },
    {
        original: 'https://i.imgur.com/peAYzRL.jpg',
        thumbnail: 'https://i.imgur.com/peAYzRL.jpg',
    },
    {
        original: 'https://i.imgur.com/8pqWAnI.jpg',
        thumbnail: 'https://i.imgur.com/8pqWAnI.jpg',
    },
    {
        original: 'https://i.imgur.com/fFZt9x8.jpg',
        thumbnail: 'https://i.imgur.com/fFZt9x8.jpg',
    },
    {
        original: 'https://i.imgur.com/fVO94nX.jpg',
        thumbnail: 'https://i.imgur.com/fVO94nX.jpg',
    },
    {
        original: 'https://i.imgur.com/gX63Sjf.jpg',
        thumbnail: 'https://i.imgur.com/gX63Sjf.jpg',
    },
    {
        original: 'https://i.imgur.com/MTuVyMn.jpg',
        thumbnail: 'https://i.imgur.com/MTuVyMn.jpg',
    },
    {
        original: 'https://i.imgur.com/U5Yztef.jpg',
        thumbnail: 'https://i.imgur.com/U5Yztef.jpg',
    },
    {
        original: 'https://i.imgur.com/HeGW4yR.jpg',
        thumbnail: 'https://i.imgur.com/HeGW4yR.jpg',
    },
    {
        original: 'https://i.imgur.com/kVKyo3j.jpg',
        thumbnail: 'https://i.imgur.com/kVKyo3j.jpg',
    },
    {
        original: 'https://i.imgur.com/E6i4GLF.jpg',
        thumbnail: 'https://i.imgur.com/E6i4GLF.jpg',
    },
];


const images5 = [
    {
        original: 'https://i.imgur.com/OUVpvJv.jpg',
        thumbnail: 'https://i.imgur.com/OUVpvJv.jpg',
    },
    {
        original: 'https://i.imgur.com/8OcNpo9.jpg',
        thumbnail: 'https://i.imgur.com/8OcNpo9.jpg',
    },
    {
        original: 'https://i.imgur.com/XUcabLA.jpg',
        thumbnail: 'https://i.imgur.com/XUcabLA.jpg',
    },
    {
        original: 'https://i.imgur.com/8aqz9eF.jpg',
        thumbnail: 'https://i.imgur.com/8aqz9eF.jpg',
    },
];

const InzenyrskeStavby3 = () => {
    return <div>
        <section id="section-1">
            <div className="pozemni">
                <div className="nadpis-pozemni">
                    <h2>Reference - Inženýrské stavby</h2>
                </div>
                <div className="picovinky-veci">
                    <h3>Komunikace a inženýrské sítě v Černošicích</h3>
                </div>
                <ImageGallery showPlayButton={false} showFullscreenButton={false} showBullets={true} items={images1} />
            </div>
        </section>
        <section id="section-2">
            <div className="pozemni">
                <div className="picovinky-veci">
                    <h3>Rekonstrukce komunikací v Dobřichovicích</h3>
                </div>
                <ImageGallery showPlayButton={false} showFullscreenButton={false} showBullets={true} items={images2} />
            </div>
        </section>
        <section id="section-3">
            <div className="pozemni">
                <div className="picovinky-veci">
                    <h3>Inženýrské sítě a komunikace v Radotíně</h3>
                </div>
                <ImageGallery showPlayButton={false} showFullscreenButton={false} showBullets={true} items={images3} />
            </div>
        </section>
        <section id="section-4">
            <div className="pozemni">
                <div className="picovinky-veci">
                    <h3>Kruhová křižovatka Dobřichovice</h3>
                </div>
                <ImageGallery showPlayButton={false} showFullscreenButton={false} showBullets={true} items={images4} />
            </div>
        </section>
        <section id="section-5-inz-3">
            <div className="pozemni">
                <div className="picovinky-veci">
                    <h3>Inženýrské sítě k bytovým domům Rotavská, Praha 5</h3>
                </div>
                <ImageGallery showPlayButton={false} showFullscreenButton={false} showBullets={true} items={images5} />
            </div>
        </section>
        <section id="strankovani">
            <div class="center">
                <div class="pagination-relas">
                    <Link to="../reference/inzenyrske-stavby">1</Link>
                    <Link to="../reference/inzenyrske-stavby-2" >2</Link>
                    <Link className="active">3</Link>
                    <Link to="../reference/inzenyrske-stavby-4">4</Link>
                    <Link to="../reference/inzenyrske-stavby-5">5</Link>
                </div>
            </div>
        </section>
    </div>
}

export default InzenyrskeStavby3