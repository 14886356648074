import { React } from "react";
import { Link } from "react-router-dom";
import "./Projekty.css"
import ImageGallery from 'react-image-gallery';

const images1 = [
    {
        original: 'https://i.imgur.com/uKR0zLx.jpg',
        thumbnail: 'https://i.imgur.com/uKR0zLx.jpg',
    },
    {
        original: 'https://i.imgur.com/ODI1L9E.jpg',
        thumbnail: 'https://i.imgur.com/ODI1L9E.jpg',
    },
    {
        original: 'https://i.imgur.com/Rbkpgl4.jpg',
        thumbnail: 'https://i.imgur.com/Rbkpgl4.jpg',
    },
    {
        original: 'https://i.imgur.com/AO7fC1V.jpg',
        thumbnail: 'https://i.imgur.com/AO7fC1V.jpg',
    },
    {
        original: 'https://i.imgur.com/x0fdGVM.jpg',
        thumbnail: 'https://i.imgur.com/x0fdGVM.jpg',
    },
    {
        original: 'https://i.imgur.com/s3aHJNX.jpg',
        thumbnail: 'https://i.imgur.com/s3aHJNX.jpg',
    },
    {
        original: 'https://i.imgur.com/FNEAVJu.jpg',
        thumbnail: 'https://i.imgur.com/FNEAVJu.jpg',
    },
    {
        original: 'https://i.imgur.com/t5fP74m.jpg',
        thumbnail: 'https://i.imgur.com/t5fP74m.jpg',
    },
    {
        original: 'https://i.imgur.com/yjd3F1l.jpg',
        thumbnail: 'https://i.imgur.com/yjd3F1l.jpg',
    },
    {
        original: 'https://i.imgur.com/2KhZGQl.jpg',
        thumbnail: 'https://i.imgur.com/2KhZGQl.jpg',
    },
];

const images2 = [
    {
        original: 'https://i.imgur.com/5SHHOQ0.jpg',
        thumbnail: 'https://i.imgur.com/5SHHOQ0.jpg',
    },
    {
        original: 'https://i.imgur.com/oiotXus.jpg',
        thumbnail: 'https://i.imgur.com/oiotXus.jpg',
    },
    {
        original: 'https://i.imgur.com/kG8Lp4l.jpg',
        thumbnail: 'https://i.imgur.com/kG8Lp4l.jpg',
    },
    {
        original: 'https://i.imgur.com/Io0bEaY.jpg',
        thumbnail: 'https://i.imgur.com/Io0bEaY.jpg',
    },
    {
        original: 'https://i.imgur.com/vQW7xmt.jpg',
        thumbnail: 'https://i.imgur.com/vQW7xmt.jpg',
    },
    {
        original: 'https://i.imgur.com/9JiiRUT.jpg',
        thumbnail: 'https://i.imgur.com/9JiiRUT.jpg',
    },
    {
        original: 'https://i.imgur.com/3aST8R4.jpg',
        thumbnail: 'https://i.imgur.com/3aST8R4.jpg',
    },
    {
        original: 'https://i.imgur.com/Z9lt2VW.jpg',
        thumbnail: 'https://i.imgur.com/Z9lt2VW.jpg',
    },
    {
        original: 'https://i.imgur.com/mEJt6WQ.jpg',
        thumbnail: 'https://i.imgur.com/mEJt6WQ.jpg',
    },
    {
        original: 'https://i.imgur.com/NKl4PTg.jpg',
        thumbnail: 'https://i.imgur.com/NKl4PTg.jpg',
    },
];

const images3 = [
    {
        original: 'https://i.imgur.com/9L3YdOw.jpg',
        thumbnail: 'https://i.imgur.com/9L3YdOw.jpg',
    },
    {
        original: 'https://i.imgur.com/yfpJCaW.jpg',
        thumbnail: 'https://i.imgur.com/yfpJCaW.jpg',
    },
    {
        original: 'https://i.imgur.com/uXSkody.jpg',
        thumbnail: 'https://i.imgur.com/uXSkody.jpg',
    },
    {
        original: 'https://i.imgur.com/rzqYRhv.jpg',
        thumbnail: 'https://i.imgur.com/rzqYRhv.jpg',
    },
];

const images4 = [
    {
        original: 'https://i.imgur.com/MI0OZ4v.jpg',
        thumbnail: 'https://i.imgur.com/MI0OZ4v.jpg',
    },
    {
        original: 'https://i.imgur.com/HVdNZQv.jpg',
        thumbnail: 'https://i.imgur.com/HVdNZQv.jpg',
    },
    {
        original: 'https://i.imgur.com/za2cSIO.jpg',
        thumbnail: 'https://i.imgur.com/za2cSIO.jpg',
    },
    {
        original: 'https://i.imgur.com/k3rdvMz.jpg',
        thumbnail: 'https://i.imgur.com/k3rdvMz.jpg',
    },
];


const images5 = [
    {
        original: 'https://i.imgur.com/jnZ0rve.jpg',
        thumbnail: 'https://i.imgur.com/jnZ0rve.jpg',
    },
    {
        original: 'https://i.imgur.com/xG8zUeV.jpg',
        thumbnail: 'https://i.imgur.com/xG8zUeV.jpg',
    },
    {
        original: 'https://i.imgur.com/v8CaXas.jpg',
        thumbnail: 'https://i.imgur.com/v8CaXas.jpg',
    },
    {
        original: 'https://i.imgur.com/EAdxrpG.jpg',
        thumbnail: 'https://i.imgur.com/EAdxrpG.jpg',
    },
];

const Projekty3 = () => {
    return <div>
        <section id="section-1">
            <div className="pozemni">
                <div className="nadpis-pozemni">
                    <h2>Reference - Projekty</h2>
                </div>
                <div className="picovinky-veci">
                    <h3>Obec Libeř - lávka pro cyklostezku</h3>
                    <ul><li>Studie</li>
                        <li>Projektová dokumentace pro územní a stavební povolení</li>
                        <li>Inženýrská činnost (ÚR+SP)</li>
                        <li>Rozpočet</li></ul>
                </div>
                <ImageGallery showPlayButton={false} showFullscreenButton={false} showBullets={true} items={images1} />
            </div>
        </section>
        <section id="section-2">
            <div className="pozemni">
                <div className="picovinky-veci">
                    <h3>Rekonstrukce místních komunikací v Černošicích</h3>
                    <ul><li>Projektová dokumentace pro stavební povolení</li>
                        <li>inženýrská činnost</li>
                        <li>Rozpočet</li>
                        <li>Autorský dozor</li></ul>
                </div>
                <ImageGallery showPlayButton={false} showFullscreenButton={false} showBullets={true} items={images2} />
            </div>
        </section>
        <section id="section-3">
            <div className="pozemni">
                <div className="picovinky-veci">
                    <h3>Novostavba RD - Radonice u Prahy</h3>
                    <ul><li>Projektová dokumentace a inženýrská činnost - ohlášení stavby</li></ul>
                </div>
                <ImageGallery showPlayButton={false} showFullscreenButton={false} showBullets={true} items={images3} />
            </div>
        </section>
        <section id="section-4">
            <div className="pozemni">
                <div className="picovinky-veci">
                    <h3>Novostavba RD - Radonice u Prahy</h3>
                    <ul><li>Studie</li>
                        <li>Projektová dokumentace pro ohlášení stavby</li>
                        <li>Inženýrská činnost</li></ul>
                </div>
                <ImageGallery showPlayButton={false} showFullscreenButton={false} showBullets={true} items={images4} />
            </div>
        </section>
        <section id="section-5-pro-3">
            <div className="pozemni">
                <div className="picovinky-veci">
                    <h3>Novostavba dvojdomu Horní Počernice</h3>
                    <ul><li>Studie</li>
                        <li>Projektová dokumentace pro stavební povolení</li>
                        <li>Inženýrská činnost</li></ul>
                </div>
                <ImageGallery showPlayButton={false} showFullscreenButton={false} showBullets={true} items={images5} />
            </div>
        </section>
        <section id="strankovani">
            <div class="center">
                <div class="pagination-relas">
                    <Link to="../reference/projekty">1</Link>
                    <Link to="../reference/projekty-2">2</Link>
                    <Link className="active">3</Link>
                    <Link to="../reference/projekty-4">4</Link>
                    <Link to="../reference/projekty-5">5</Link>
                    <Link to="../reference/projekty-6">6</Link>
                </div>
            </div>
        </section>
    </div>
}

export default Projekty3