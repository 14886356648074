import { React } from "react";
import { Link } from "react-router-dom";
import "./InzenyrskeStavby.css"
import ImageGallery from 'react-image-gallery';

const images1 = [
    {
        original: 'https://i.imgur.com/DO4nbNm.jpg',
        thumbnail: 'https://i.imgur.com/DO4nbNm.jpg',
    },
    {
        original: 'https://i.imgur.com/QqyNQUl.jpg',
        thumbnail: 'https://i.imgur.com/QqyNQUl.jpg',
    },
    {
        original: 'https://i.imgur.com/m2r4n9E.jpg',
        thumbnail: 'https://i.imgur.com/m2r4n9E.jpg',
    },
    {
        original: 'https://i.imgur.com/t8gBoHO.jpg',
        thumbnail: 'https://i.imgur.com/t8gBoHO.jpg',
    },
    {
        original: 'https://i.imgur.com/J8C0xpq.jpg',
        thumbnail: 'https://i.imgur.com/J8C0xpq.jpg',
    },
    {
        original: 'https://i.imgur.com/MlNmrbP.jpg',
        thumbnail: 'https://i.imgur.com/MlNmrbP.jpg',
    },
    {
        original: 'https://i.imgur.com/maBr8HL.jpg',
        thumbnail: 'https://i.imgur.com/maBr8HL.jpg',
    },
    {
        original: 'https://i.imgur.com/7kAiksJ.jpg',
        thumbnail: 'https://i.imgur.com/7kAiksJ.jpg',
    },
    {
        original: 'https://i.imgur.com/v2BH5QI.jpg',
        thumbnail: 'https://i.imgur.com/v2BH5QI.jpg',
    },
];

const images2 = [
    {
        original: 'https://i.imgur.com/qnQylr1.jpg',
        thumbnail: 'https://i.imgur.com/qnQylr1.jpg',
    },
    {
        original: 'https://i.imgur.com/eS54HiR.jpg',
        thumbnail: 'https://i.imgur.com/eS54HiR.jpg',
    },
    {
        original: 'https://i.imgur.com/BlO7fw1.jpg',
        thumbnail: 'https://i.imgur.com/BlO7fw1.jpg',
    },
    {
        original: 'https://i.imgur.com/f13ISog.jpg',
        thumbnail: 'https://i.imgur.com/f13ISog.jpg',
    },
    {
        original: 'https://i.imgur.com/rcDq8cT.jpg',
        thumbnail: 'https://i.imgur.com/rcDq8cT.jpg',
    },
    {
        original: 'https://i.imgur.com/O2QoZvI.jpg',
        thumbnail: 'https://i.imgur.com/O2QoZvI.jpg',
    },
    {
        original: 'https://i.imgur.com/4wGxx9C.jpg',
        thumbnail: 'https://i.imgur.com/4wGxx9C.jpg',
    },
    {
        original: 'https://i.imgur.com/JCogCCO.jpg',
        thumbnail: 'https://i.imgur.com/JCogCCO.jpg',
    },
    {
        original: 'https://i.imgur.com/sKOQuiZ.jpg',
        thumbnail: 'https://i.imgur.com/sKOQuiZ.jpg',
    },
];

const images3 = [
    {
        original: 'https://i.imgur.com/PxO0y3Y.jpg',
        thumbnail: 'https://i.imgur.com/PxO0y3Y.jpg',
    },
    {
        original: 'https://i.imgur.com/lOJNsxC.jpg',
        thumbnail: 'https://i.imgur.com/lOJNsxC.jpg',
    },
    {
        original: 'https://i.imgur.com/XNxfnF3.jpg',
        thumbnail: 'https://i.imgur.com/XNxfnF3.jpg',
    },
    {
        original: 'https://i.imgur.com/14CTECH.jpg',
        thumbnail: 'https://i.imgur.com/14CTECH.jpg',
    },
    {
        original: 'https://i.imgur.com/L7A5OLa.jpg',
        thumbnail: 'https://i.imgur.com/L7A5OLa.jpg',
    },
    {
        original: 'https://i.imgur.com/pr1TW73.jpg',
        thumbnail: 'https://i.imgur.com/pr1TW73.jpg',
    },
    {
        original: 'https://i.imgur.com/47Jrn4s.jpg',
        thumbnail: 'https://i.imgur.com/47Jrn4s.jpg',
    },
    {
        original: 'https://i.imgur.com/PcWTBM0.jpg',
        thumbnail: 'https://i.imgur.com/PcWTBM0.jpg',
    },
    {
        original: 'https://i.imgur.com/K9BNq1V.jpg',
        thumbnail: 'https://i.imgur.com/K9BNq1V.jpg',
    },
    {
        original: 'https://i.imgur.com/u6s72pf.jpg',
        thumbnail: 'https://i.imgur.com/u6s72pf.jpg',
    },
    {
        original: 'https://i.imgur.com/mQrcbro.jpg',
        thumbnail: 'https://i.imgur.com/mQrcbro.jpg',
    },
    {
        original: 'https://i.imgur.com/lV80o0Z.jpg',
        thumbnail: 'https://i.imgur.com/lV80o0Z.jpg',
    },
    {
        original: 'https://i.imgur.com/0p7mkdR.jpg',
        thumbnail: 'https://i.imgur.com/0p7mkdR.jpg',
    },
];

const images4 = [
    {
        original: 'https://i.imgur.com/0fdGBW4.jpg',
        thumbnail: 'https://i.imgur.com/0fdGBW4.jpg',
    },
    {
        original: 'https://i.imgur.com/BU7FoFz.jpg',
        thumbnail: 'https://i.imgur.com/BU7FoFz.jpg',
    },
    {
        original: 'https://i.imgur.com/elnvsJM.jpg',
        thumbnail: 'https://i.imgur.com/elnvsJM.jpg',
    },
    {
        original: 'https://i.imgur.com/UBxSFym.jpg',
        thumbnail: 'https://i.imgur.com/UBxSFym.jpg',
    },
    {
        original: 'https://i.imgur.com/7DBrQ9A.jpg',
        thumbnail: 'https://i.imgur.com/7DBrQ9A.jpg',
    },
    {
        original: 'https://i.imgur.com/z4g4fJW.jpg',
        thumbnail: 'https://i.imgur.com/z4g4fJW.jpg',
    },
    {
        original: 'https://i.imgur.com/tG0p2UW.jpg',
        thumbnail: 'https://i.imgur.com/tG0p2UW.jpg',
    },
    {
        original: 'https://i.imgur.com/sxfdluw.jpg',
        thumbnail: 'https://i.imgur.com/sxfdluw.jpg',
    },
    {
        original: 'https://i.imgur.com/qpVCc2S.jpg',
        thumbnail: 'https://i.imgur.com/qpVCc2S.jpg',
    },
    {
        original: 'https://i.imgur.com/eJbcIBR.jpg',
        thumbnail: 'https://i.imgur.com/eJbcIBR.jpg',
    },
    {
        original: 'https://i.imgur.com/fzXg9JS.jpg',
        thumbnail: 'https://i.imgur.com/fzXg9JS.jpg',
    },
    {
        original: 'https://i.imgur.com/kMDDc0I.jpg',
        thumbnail: 'https://i.imgur.com/kMDDc0I.jpg',
    },
    {
        original: 'https://i.imgur.com/tQOpVMi.jpg',
        thumbnail: 'https://i.imgur.com/tQOpVMi.jpg',
    },
    {
        original: 'https://i.imgur.com/XnaIOFV.jpg',
        thumbnail: 'https://i.imgur.com/XnaIOFV.jpg',
    },
    {
        original: 'https://i.imgur.com/QNNsVBc.jpg',
        thumbnail: 'https://i.imgur.com/QNNsVBc.jpg',
    },
    {
        original: 'https://i.imgur.com/VSXReDU.jpg',
        thumbnail: 'https://i.imgur.com/VSXReDU.jpg',
    },
];


const images5 = [
    {
        original: 'https://i.imgur.com/VhnBO2u.jpg',
        thumbnail: 'https://i.imgur.com/VhnBO2u.jpg',
    },
    {
        original: 'https://i.imgur.com/DVhsPG5.jpg',
        thumbnail: 'https://i.imgur.com/DVhsPG5.jpg',
    },
    {
        original: 'https://i.imgur.com/u0dj4lv.jpg',
        thumbnail: 'https://i.imgur.com/u0dj4lv.jpg',
    },
    {
        original: 'https://i.imgur.com/UcKrFcX.jpg',
        thumbnail: 'https://i.imgur.com/UcKrFcX.jpg',
    },
    {
        original: 'https://i.imgur.com/UPCdL8w.jpg',
        thumbnail: 'https://i.imgur.com/UPCdL8w.jpg',
    },
    {
        original: 'https://i.imgur.com/bG7xEiz.jpg',
        thumbnail: 'https://i.imgur.com/bG7xEiz.jpg',
    },
    {
        original: 'https://i.imgur.com/Y343I00.jpg',
        thumbnail: 'https://i.imgur.com/Y343I00.jpg',
    },
    {
        original: 'https://i.imgur.com/L6lYLMI.jpg',
        thumbnail: 'https://i.imgur.com/L6lYLMI.jpg',
    },
    {
        original: 'https://i.imgur.com/NvZn8po.jpg',
        thumbnail: 'https://i.imgur.com/NvZn8po.jpg',
    },
    {
        original: 'https://i.imgur.com/OatZZs4.jpg',
        thumbnail: 'https://i.imgur.com/OatZZs4.jpg',
    },
    {
        original: 'https://i.imgur.com/rc2Ny8q.jpg',
        thumbnail: 'https://i.imgur.com/rc2Ny8q.jpg',
    },
];

const InzenyrskeStavby2 = () => {
    return <div>
        <section id="section-1">
            <div className="pozemni">
                <div className="nadpis-pozemni">
                    <h2>Reference - Inženýrské stavby</h2>
                </div>
                <div className="picovinky-veci">
                    <h3>Komunikace v Horních Počernicích, ulice Sekeřická</h3>
                    <ul><li>Dešťová kanalizace</li>
                        <li>Přeložky inženýrských sítí</li>
                        <li>Rekonstrukce komunikací a chodníků</li>
                        <li>Sadové úpravy</li></ul>
                </div>
                <ImageGallery showPlayButton={false} showFullscreenButton={false} showBullets={true} items={images1} />
            </div>
        </section>
        <section id="section-2">
            <div className="pozemni">
                <div className="picovinky-veci">
                    <h3>Revitalizace rybníků v Dolních Břežanech</h3>
                    <ul><li>Vyčištění rybníků</li>
                        <li>Úprava břehů</li>
                        <li>Úprava vtokových a výtokových objektů z lomového kamene</li>
                        <li>Komunikace a chodníky</li></ul>
                </div>
                <ImageGallery showPlayButton={false} showFullscreenButton={false} showBullets={true} items={images2} />
            </div>
        </section>
        <section id="section-3">
            <div className="pozemni">
                <div className="picovinky-veci">
                    <h3>Autobusové zastávky Horoušánky, Jirny, Šestajovice</h3>
                    <ul><li>Rekonstrukce inženýrských sítí</li>
                        <li>Nové povrchy zastávek včetně nástupních ostrůvků</li>
                        <li>Nové čekárny</li>
                        <li>Nové veřejné osvětlení zastávek</li></ul>
                </div>
                <ImageGallery showPlayButton={false} showFullscreenButton={false} showBullets={true} items={images3} />
            </div>
        </section>
        <section id="section-4">
            <div className="pozemni">
                <div className="picovinky-veci">
                    <h3>Rekonstrukce historické návsi - Dolní Břežany</h3>
                    <ul><li>Rekonstrukce dešťové kanalizace</li>
                        <li>Rekonstrukce povrchů návsi</li>
                        <li>Veřejné osvětlení</li>
                        <li>Sadové úpravy</li></ul>
                </div>
                <ImageGallery showPlayButton={false} showFullscreenButton={false} showBullets={true} items={images4} />
            </div>
        </section>
        <section id="section-5-inz-2">
            <div className="pozemni">
                <div className="picovinky-veci">
                    <h3>Inženýrské sítě a komunikace Radonice u Prahy</h3>
                    <ul><li>Splašková kanalizace včetně přípojek a revizních šachet</li>
                        <li>Dešťová kanalizace včetně přípojek a uličních vpustí</li>
                        <li>Retenční nádrž</li>
                        <li>Vodovod včetně přípojek a vodoměrných šachet</li>
                        <li>Plynovod včetně přípojek a kiosků pro měření</li>
                        <li>Veřejné osvětlení</li>
                        <li>Komunikace ze zámkové dlažby</li>
                        <li>Komunikace ze živice</li>
                        <li>Sportovní hřiště</li>
                        <li>Dětská hřiště</li></ul>
                </div>
                <ImageGallery showPlayButton={false} showFullscreenButton={false} showBullets={true} items={images5} />
            </div>
        </section>
        <section id="strankovani">
            <div class="center">
                <div class="pagination-relas">
                    <Link to="../reference/inzenyrske-stavby">1</Link>
                    <Link className="active">2</Link>
                    <Link to="../reference/inzenyrske-stavby-3">3</Link>
                    <Link to="../reference/inzenyrske-stavby-4">4</Link>
                    <Link to="../reference/inzenyrske-stavby-5">5</Link>
                </div>
            </div>
        </section>
    </div>
}

export default InzenyrskeStavby2