import { React } from "react";
import { Link } from "react-router-dom";
import "./Pozemni-stavby-2.css"
import ImageGallery from 'react-image-gallery';

const images1 = [
    {
        original: 'https://i.imgur.com/1RFpMRk.jpg',
        thumbnail: 'https://i.imgur.com/1RFpMRk.jpg',
    },
    {
        original: 'https://i.imgur.com/qxYqW0N.jpg',
        thumbnail: 'https://i.imgur.com/qxYqW0N.jpg',
    },
    {
        original: 'https://i.imgur.com/TngCQtZ.jpg',
        thumbnail: 'https://i.imgur.com/TngCQtZ.jpg',
    },
    {
        original: 'https://i.imgur.com/3u8E12W.jpg',
        thumbnail: 'https://i.imgur.com/3u8E12W.jpg',
    },
    {
        original: 'https://i.imgur.com/d90Xyfa.jpg',
        thumbnail: 'https://i.imgur.com/d90Xyfa.jpg',
    },
    {
        original: 'https://i.imgur.com/yVenB1N.jpg',
        thumbnail: 'https://i.imgur.com/yVenB1N.jpg',
    },
    {
        original: 'https://i.imgur.com/uH7EyCl.jpg',
        thumbnail: 'https://i.imgur.com/uH7EyCl.jpg',
    },
    {
        original: 'https://i.imgur.com/eaviIJj.jpg',
        thumbnail: 'https://i.imgur.com/eaviIJj.jpg',
    },
    {
        original: 'https://i.imgur.com/rn1Ct5Z.jpg',
        thumbnail: 'https://i.imgur.com/rn1Ct5Z.jpg',
    },
    {
        original: 'https://i.imgur.com/gVdKE5d.jpg',
        thumbnail: 'https://i.imgur.com/gVdKE5d.jpg',
    },
];

const images2 = [
    {
        original: 'https://i.imgur.com/RJ9kvv1.jpg',
        thumbnail: 'https://i.imgur.com/RJ9kvv1.jpg',
    },
    {
        original: 'https://i.imgur.com/HJyktpf.jpg',
        thumbnail: 'https://i.imgur.com/HJyktpf.jpg',
    },
    {
        original: 'https://i.imgur.com/EMkKxQQ.jpg',
        thumbnail: 'https://i.imgur.com/EMkKxQQ.jpg',
    },
    {
        original: 'https://i.imgur.com/l7rDRfZ.jpg',
        thumbnail: 'https://i.imgur.com/l7rDRfZ.jpg',
    },
    {
        original: 'https://i.imgur.com/SMoYWaT.jpg',
        thumbnail: 'https://i.imgur.com/SMoYWaT.jpg',
    },
    {
        original: 'https://i.imgur.com/orORaBO.jpg',
        thumbnail: 'https://i.imgur.com/orORaBO.jpg',
    },
    {
        original: 'https://i.imgur.com/t6gnwTd.jpg',
        thumbnail: 'https://i.imgur.com/t6gnwTd.jpg',
    },
    {
        original: 'https://i.imgur.com/M9SOgU1.jpg',
        thumbnail: 'https://i.imgur.com/M9SOgU1.jpg',
    },
    {
        original: 'https://i.imgur.com/FtzkrLO.jpg',
        thumbnail: 'https://i.imgur.com/FtzkrLO.jpg',
    },
];

const images3 = [
    {
        original: 'https://i.imgur.com/UUMRkvi.jpg',
        thumbnail: 'https://i.imgur.com/UUMRkvi.jpg',
    },
    {
        original: 'https://i.imgur.com/gpeYStI.jpg',
        thumbnail: 'https://i.imgur.com/gpeYStI.jpg',
    },
    {
        original: 'https://i.imgur.com/vhtYHnB.jpg',
        thumbnail: 'https://i.imgur.com/vhtYHnB.jpg',
    },
    {
        original: 'https://i.imgur.com/htCbKTi.jpg',
        thumbnail: 'https://i.imgur.com/htCbKTi.jpg',
    },
    {
        original: 'https://i.imgur.com/6aiUV0g.jpg',
        thumbnail: 'https://i.imgur.com/6aiUV0g.jpg',
    },
    {
        original: 'https://i.imgur.com/OlAhBoj.jpg',
        thumbnail: 'https://i.imgur.com/OlAhBoj.jpg',
    },
    {
        original: 'https://i.imgur.com/2zciwkR.jpg',
        thumbnail: 'https://i.imgur.com/2zciwkR.jpg',
    },
    {
        original: 'https://i.imgur.com/H5tCfq6.jpg',
        thumbnail: 'https://i.imgur.com/H5tCfq6.jpg',
    },
    {
        original: 'https://i.imgur.com/OTtL2Zt.jpg',
        thumbnail: 'https://i.imgur.com/OTtL2Zt.jpg',
    },
    {
        original: 'https://i.imgur.com/09L6TFy.jpg',
        thumbnail: 'https://i.imgur.com/09L6TFy.jpg',
    },
];

const images4 = [
    {
        original: 'https://i.imgur.com/IvarOTD.jpg',
        thumbnail: 'https://i.imgur.com/IvarOTD.jpg',
    },
    {
        original: 'https://i.imgur.com/YMZvh4B.jpg',
        thumbnail: 'https://i.imgur.com/YMZvh4B.jpg',
    },
    {
        original: 'https://i.imgur.com/xXfBcle.jpg',
        thumbnail: 'https://i.imgur.com/xXfBcle.jpg',
    },
    {
        original: 'https://i.imgur.com/GlVXaqp.jpg',
        thumbnail: 'https://i.imgur.com/GlVXaqp.jpg',
    },
    {
        original: 'https://i.imgur.com/Cqm0BOP.jpg',
        thumbnail: 'https://i.imgur.com/Cqm0BOP.jpg',
    },
    {
        original: 'https://i.imgur.com/Nkk0H6c.jpg',
        thumbnail: 'https://i.imgur.com/Nkk0H6c.jpg',
    },
    {
        original: 'https://i.imgur.com/VmfKWKp.jpg',
        thumbnail: 'https://i.imgur.com/VmfKWKp.jpg',
    },
    {
        original: 'https://i.imgur.com/P6AoBLK.jpg',
        thumbnail: 'https://i.imgur.com/P6AoBLK.jpg',
    },
    {
        original: 'https://i.imgur.com/1UgQJPU.jpg',
        thumbnail: 'https://i.imgur.com/1UgQJPU.jpg',
    },
    {
        original: 'https://i.imgur.com/1F9bwIR.jpg',
        thumbnail: 'https://i.imgur.com/1F9bwIR.jpg',
    },
    {
        original: 'https://i.imgur.com/bqMiG6M.jpg',
        thumbnail: 'https://i.imgur.com/bqMiG6M.jpg',
    },
];


const images5 = [
    {
        original: 'https://i.imgur.com/7s5CpFO.jpg',
        thumbnail: 'https://i.imgur.com/7s5CpFO.jpg',
    },
    {
        original: 'https://i.imgur.com/zaGQal6.jpg',
        thumbnail: 'https://i.imgur.com/zaGQal6.jpg',
    },
    {
        original: 'https://i.imgur.com/cctD6HU.jpg',
        thumbnail: 'https://i.imgur.com/cctD6HU.jpg',
    },
    {
        original: 'https://i.imgur.com/iSFgBk7.jpg',
        thumbnail: 'https://i.imgur.com/iSFgBk7.jpg',
    },
    {
        original: 'https://i.imgur.com/fDvWEi9.jpg',
        thumbnail: 'https://i.imgur.com/fDvWEi9.jpg',
    },
];

const PozemniStavby2 = () => {
    return <div>
        <section id="section-1-2">
            <div className="pozemni">
                <div className="nadpis-pozemni">
                    <h2>Reference - Pozemní stavby</h2>
                </div>
                <div className="picovinky-veci">
                    <h3>Skleník botanické zahrady v areálu SOŠ stavební a zahradnické, Hrdlořezy</h3>
                    <ul
                    ><li>I. etapa: Provozní budova ke skleníku</li>
                        <li>II. etapa: Objekt vlastního skleníku</li>
                        <li>dešťová kanalizace</li>
                        <li>komunikace</li>
                        <li>terénní úpravy</li>
                        <li>teplovod</li>
                    </ul>
                </div>
                <ImageGallery showPlayButton={false} showFullscreenButton={false} showBullets={true} items={images1} />
            </div>
        </section>
        <section id="section-2">
            <div className="pozemni">
                <div className="picovinky-veci">
                    <h3>Novostavba provozně skladového areálu místního hospodářství v Horních Počernicích</h3>
                    <ul>
                        <li>Novostavba zahrnující skladové, administrativní a bytové prostory</li>
                        <li>Venkovní plochy cca 600m2 pro pojezd těžkou mechanizací</li>
                        <li>Přípojky inženýrských sítí</li>
                    </ul>
                </div>
                <ImageGallery showPlayButton={false} showFullscreenButton={false} showBullets={true} items={images2} />
            </div>
        </section>
        <section id="section-3">
            <div className="pozemni">
                <div className="picovinky-veci">
                    <h3>Hotel Chvalská tvrz v Horních Počernicích</h3>
                </div>
                <ImageGallery showPlayButton={false} showFullscreenButton={false} showBullets={true} items={images3} />
            </div>
        </section>
        <section id="section-4">
            <div className="pozemni">
                <div className="picovinky-veci">
                    <h3>Rekonstrukce botanické zahrady, Hrdlořezy</h3>
                </div>
                <ImageGallery showPlayButton={false} showFullscreenButton={false} showBullets={true} items={images4} />
            </div>
        </section>
        <section id="section-5-2">
            <div className="pozemni">
                <div className="picovinky-veci">
                    <h3>Rekonstrukce hotelu Lysiny Praha 4</h3>
                </div>
                <ImageGallery showPlayButton={false} showFullscreenButton={false} showBullets={true} items={images5} />
            </div>
        </section>
        <section id="strankovani">
            <div class="center">
                <div class="pagination-relas">
                    <Link to="../reference/pozemni-stavby">1</Link>
                    <Link className="active">2</Link>
                    <Link to="../reference/pozemni-stavby-3">3</Link>
                    <Link to="../reference/pozemni-stavby-4">4</Link>
                </div>
            </div>
        </section>
    </div>
}

export default PozemniStavby2