import "./Home.css";
import { Helmet } from "react-helmet";
import { Slide } from 'react-slideshow-image';
import logo from "../img/logo_-_ekis_-__klasicke_na_bile__-_100x37mm_-_cmyk-removebg-preview.png"
const Home = () => {
      
      const slideImages = [
        {
          url: 'https://i.imgur.com/bqT7ZDq.jpg'
        },
        {
          url: 'https://i.imgur.com/vfF01Xd.jpg'
        },
        {
          url: 'https://i.imgur.com/AyoFLSm.jpg'
        },
        {
          url: 'https://i.imgur.com/ApN9ZBj.png'
        },
        {
          url: 'https://i.imgur.com/ew2nnSo.jpg'
        },
        {
          url: 'https://i.imgur.com/SolziI7.jpg'
        },
        {
          url: 'https://i.imgur.com/BE8vreS.jpg'
        },
        {
          url: 'https://i.imgur.com/Q5Eb2O0.jpg'
        },
        {
          url: 'https://i.imgur.com/seSIukM.jpg'
        },
      ];

    return (
    <>
    <Helmet>
    <title>Úvodní stránka</title>
<meta name="title" content="EKIS"/>
<meta name="description" content="Úvodní stránka stavební společnosti EKIS"/>
<meta property="og:type" content="website"/>
<meta property="og:url" content="http://ekis-as.cz/"/>
<meta property="og:title" content="EKIS"/>
<meta property="og:description" content="Úvodní stránka stavební společnosti EKIS"/>
<meta property="og:image" content=""></meta>
    </Helmet>
        <section id="slide-show">
        <div className="slide-container">
        <Slide autoplay={true}
        transitionDuration={400}
        pauseOnHover={true}>
         {slideImages.map((slideImage, index)=> (
            <div key={index}>
              <div className="relas-majik" style={{ 'backgroundImage': `url(${slideImage.url})`}}>
              </div>
            </div>
          ))} 
        </Slide>
      </div>
      </section>
      <section id="vitejte">
        <div className="vitejte-relas">
          <div className="textik-uvod">
        <p>Naše společnost se zabývá realizací staveb. Provádíme především pozemní a inženýrské stavby.</p>
        <p>V oblasti pozemních staveb se specializujeme na výstavbu a rekonstrukce občanských, komerčních a průmyslových staveb.</p>
        <p>V oblasti inženýrských staveb se zaměřujeme na novostavby a rekonstrukce technické vybavenosti městské infrastruktury (kanalizace, vodovodů, plynovodů, rozvodů nízkého napětí a komunikací)</p>
        <p>Dále našim zákazníkům nabízíme zpracování projektové dokumentace staveb, zeměměřické práce, inženýrské činnosti a činnosti v oblasti bezpečnosti práce.</p>
        <p>Oblastí našeho zájmu jsou především Praha a Středočeský kraj.</p>
        </div>
        <div className="logo-main">
        <img src={logo} alt="logo" />
        </div>
        <h5>STAVBY PRO VÁS</h5>
        </div>
      </section>
    </>
    )
}

export default Home