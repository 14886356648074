import { React } from "react";
import { Link } from "react-router-dom";
import "./InzenyrskeStavby.css"
import ImageGallery from 'react-image-gallery';

const images1 = [
    {
        original: 'https://i.imgur.com/Q30DN5j.jpg',
        thumbnail: 'https://i.imgur.com/Q30DN5j.jpg',
    },
    {
        original: 'https://i.imgur.com/Yu3Zj8h.jpg',
        thumbnail: 'https://i.imgur.com/Yu3Zj8h.jpg',
    },
    {
        original: 'https://i.imgur.com/mYiJzQo.jpg',
        thumbnail: 'https://i.imgur.com/mYiJzQo.jpg',
    },
    {
        original: 'https://i.imgur.com/MwvFSmc.jpg',
        thumbnail: 'https://i.imgur.com/MwvFSmc.jpg',
    },
    {
        original: 'https://i.imgur.com/D27CqhO.jpg',
        thumbnail: 'https://i.imgur.com/D27CqhO.jpg',
    },
    {
        original: 'https://i.imgur.com/8KP9jqv.jpg',
        thumbnail: 'https://i.imgur.com/8KP9jqv.jpg',
    },
    {
        original: 'https://i.imgur.com/r4ooPNa.jpg',
        thumbnail: 'https://i.imgur.com/r4ooPNa.jpg',
    },
    {
        original: 'https://i.imgur.com/C4mXHqR.jpg',
        thumbnail: 'https://i.imgur.com/C4mXHqR.jpg',
    },
    {
        original: 'https://i.imgur.com/LWWVwPF.jpg',
        thumbnail: 'https://i.imgur.com/LWWVwPF.jpg',
    },
    {
        original: 'https://i.imgur.com/si7fJM9.jpg',
        thumbnail: 'https://i.imgur.com/si7fJM9.jpg',
    },
    {
        original: 'https://i.imgur.com/RotraGO.jpg',
        thumbnail: 'https://i.imgur.com/RotraGO.jpg',
    },
    {
        original: 'https://i.imgur.com/Q4nhrGa.jpg',
        thumbnail: 'https://i.imgur.com/Q4nhrGa.jpg',
    },
    {
        original: 'https://i.imgur.com/KubVb3N.jpg',
        thumbnail: 'https://i.imgur.com/KubVb3N.jpg',
    },
    {
        original: 'https://i.imgur.com/XrJ2Kkg.jpg',
        thumbnail: 'https://i.imgur.com/XrJ2Kkg.jpg',
    },
    {
        original: 'https://i.imgur.com/OoghneL.jpg',
        thumbnail: 'https://i.imgur.com/OoghneL.jpg',
    },
    {
        original: 'https://i.imgur.com/hHdVXg9.jpg',
        thumbnail: 'https://i.imgur.com/hHdVXg9.jpg',
    },
];

const images2 = [
    {
        original: 'https://i.imgur.com/Dk1v2OT.jpg',
        thumbnail: 'https://i.imgur.com/Dk1v2OT.jpg',
    },
    {
        original: 'https://i.imgur.com/SXPbSQb.jpg',
        thumbnail: 'https://i.imgur.com/SXPbSQb.jpg',
    },
    {
        original: 'https://i.imgur.com/ZTUCOvn.jpg',
        thumbnail: 'https://i.imgur.com/ZTUCOvn.jpg',
    },
    {
        original: 'https://i.imgur.com/JR1eqYR.jpg',
        thumbnail: 'https://i.imgur.com/JR1eqYR.jpg',
    },
    {
        original: 'https://i.imgur.com/xLIcxTj.jpg',
        thumbnail: 'https://i.imgur.com/xLIcxTj.jpg',
    },
    {
        original: 'https://i.imgur.com/9qsubzm.jpg',
        thumbnail: 'https://i.imgur.com/9qsubzm.jpg',
    },
    {
        original: 'https://i.imgur.com/fXzNpgD.jpg',
        thumbnail: 'https://i.imgur.com/fXzNpgD.jpg',
    },
    {
        original: 'https://i.imgur.com/eTsVRUz.jpg',
        thumbnail: 'https://i.imgur.com/eTsVRUz.jpg',
    },
    {
        original: 'https://i.imgur.com/q2v8d0b.jpg',
        thumbnail: 'https://i.imgur.com/q2v8d0b.jpg',
    },
    {
        original: 'https://i.imgur.com/uOlcpVs.jpg',
        thumbnail: 'https://i.imgur.com/uOlcpVs.jpg',
    },
    {
        original: 'https://i.imgur.com/iFc5epl.jpg',
        thumbnail: 'https://i.imgur.com/iFc5epl.jpg',
    },
    {
        original: 'https://i.imgur.com/OtnN7QD.jpg',
        thumbnail: 'https://i.imgur.com/OtnN7QD.jpg',
    },
    {
        original: 'https://i.imgur.com/NIAHYVx.jpg',
        thumbnail: 'https://i.imgur.com/NIAHYVx.jpg',
    },
    {
        original: 'https://i.imgur.com/gvn3A6T.jpg',
        thumbnail: 'https://i.imgur.com/gvn3A6T.jpg',
    },
    {
        original: 'https://i.imgur.com/apOGzMT.jpg',
        thumbnail: 'https://i.imgur.com/apOGzMT.jpg',
    },
];

const images3 = [
    {
        original: 'https://i.imgur.com/F3bBrrs.jpg',
        thumbnail: 'https://i.imgur.com/F3bBrrs.jpg',
    },
    {
        original: 'https://i.imgur.com/m1AwpHC.jpg',
        thumbnail: 'https://i.imgur.com/m1AwpHC.jpg',
    },
    {
        original: 'https://i.imgur.com/qCF231n.jpg',
        thumbnail: 'https://i.imgur.com/qCF231n.jpg',
    },
    {
        original: 'https://i.imgur.com/qoaCT33.jpg',
        thumbnail: 'https://i.imgur.com/qoaCT33.jpg',
    },
    {
        original: 'https://i.imgur.com/F63Q2J3.jpg',
        thumbnail: 'https://i.imgur.com/F63Q2J3.jpg',
    },
    {
        original: 'https://i.imgur.com/on34FdX.jpg',
        thumbnail: 'https://i.imgur.com/on34FdX.jpg',
    },
    {
        original: 'https://i.imgur.com/iuNzCo3.jpg',
        thumbnail: 'https://i.imgur.com/iuNzCo3.jpg',
    },
];

const images4 = [
    {
        original: 'https://i.imgur.com/dQXciYl.jpg',
        thumbnail: 'https://i.imgur.com/dQXciYl.jpg',
    },
    {
        original: 'https://i.imgur.com/Ar7Nrsz.jpg',
        thumbnail: 'https://i.imgur.com/Ar7Nrsz.jpg',
    },
    {
        original: 'https://i.imgur.com/2TPDI7E.jpg',
        thumbnail: 'https://i.imgur.com/2TPDI7E.jpg',
    },
    {
        original: 'https://i.imgur.com/i0CCtid.jpg',
        thumbnail: 'https://i.imgur.com/i0CCtid.jpg',
    },
    {
        original: 'https://i.imgur.com/rG7lBad.jpg',
        thumbnail: 'https://i.imgur.com/rG7lBad.jpg',
    },
    {
        original: 'https://i.imgur.com/yDab1Xy.jpg',
        thumbnail: 'https://i.imgur.com/yDab1Xy.jpg',
    },
    {
        original: 'https://i.imgur.com/ZLoaLes.jpg',
        thumbnail: 'https://i.imgur.com/ZLoaLes.jpg',
    },
    {
        original: 'https://i.imgur.com/njxg8ei.jpg',
        thumbnail: 'https://i.imgur.com/njxg8ei.jpg',
    },
    {
        original: 'https://i.imgur.com/3TLCarY.jpg',
        thumbnail: 'https://i.imgur.com/3TLCarY.jpg',
    },
    {
        original: 'https://i.imgur.com/2t2LbLk.jpg',
        thumbnail: 'https://i.imgur.com/2t2LbLk.jpg',
    },
];


const images5 = [
    {
        original: 'https://i.imgur.com/QF9KZSF.jpg',
        thumbnail: 'https://i.imgur.com/QF9KZSF.jpg',
    },
    {
        original: 'https://i.imgur.com/YuPXrHV.jpg',
        thumbnail: 'https://i.imgur.com/YuPXrHV.jpg',
    },
    {
        original: 'https://i.imgur.com/h0sGZlc.jpg',
        thumbnail: 'https://i.imgur.com/h0sGZlc.jpg',
    },
    {
        original: 'https://i.imgur.com/OVCtCQS.jpg',
        thumbnail: 'https://i.imgur.com/OVCtCQS.jpg',
    },
    {
        original: 'https://i.imgur.com/p7Mw5eY.jpg',
        thumbnail: 'https://i.imgur.com/p7Mw5eY.jpg',
    },
    {
        original: 'https://i.imgur.com/hzBstrX.jpg',
        thumbnail: 'https://i.imgur.com/hzBstrX.jpg',
    },
    {
        original: 'https://i.imgur.com/zfmuLDf.jpg',
        thumbnail: 'https://i.imgur.com/zfmuLDf.jpg',
    },
];

const InzenyrskeStavby = () => {
    return <div>
        <section id="section-1">
            <div className="pozemni">
                <div className="nadpis-pozemni">
                    <h2>Reference - Inženýrské stavby</h2>
                </div>
                <div className="picovinky-veci">
                    <h3>Rekonstrukce ulice U Stadiónu, Mladá Boleslav</h3>
                    <ul>
                        <li>Výměna podkladních vrstev komunikací</li>
                        <li>Nové povrchy ze živice</li>
                        <li>Nové povrchy ze zámkové dlažby</li>
                        <li>Veřejné osvětlení</li>
                        <li>Dešťová kanalizace</li>
                        <li>Mobiliář</li>
                        <li>Sadové úpravy</li>
                    </ul>
                </div>
                <ImageGallery showPlayButton={false} showFullscreenButton={false} showBullets={true} items={images1} />
            </div>
        </section>
        <section id="section-2">
            <div className="pozemni">
                <div className="picovinky-veci">
                    <h3>Biotop Radotín</h3>
                    <ul>
                        <li>Nádrže pro koupání a čištění</li>
                        <li>Studny</li>
                        <li>Rozvody kanalizace a vodovodů</li>
                        <li>Veřejné osvětlení</li>
                        <li>Parkoviště</li>
                        <li>Sadové úpravy</li>
                        <li>Oplocení</li>
                    </ul>
                </div>
                <ImageGallery showPlayButton={false} showFullscreenButton={false} showBullets={true} items={images2} />
            </div>
        </section>
        <section id="section-3">
            <div className="pozemni">
                <div className="picovinky-veci">
                    <h3>Inženýrské sítě a komunikace v obci Bášť</h3>
                    <ul><li>Dešťová a splašková kanalizace</li>
                        <li>Vodovod</li>
                        <li>Plynovod</li>
                        <li>Veřejné osvětlení </li>
                        <li>Komunikace</li>
                        <li>Infrastruktura pro 57 stavebních pozemků</li></ul>
                </div>
                <ImageGallery showPlayButton={false} showFullscreenButton={false} showBullets={true} items={images3} />
            </div>
        </section>
        <section id="section-4">
            <div className="pozemni">
                <div className="picovinky-veci">
                    <h3>Inženýrské stavby v obci Libeř</h3>
                    <ul><li>Cyklostezka</li>
                        <li>Oprava historického mostu</li>
                        <li>Lávka pro pěší a pro cyklisty</li>
                        <li>Schody ke kostelu</li>
                        <li>Dešťová kanalizace</li>
                        <li>Veřejné osvětlení</li></ul>
                </div>
                <ImageGallery showPlayButton={false} showFullscreenButton={false} showBullets={true} items={images4} />
            </div>
        </section>
        <section id="section-5-inz">
            <div className="pozemni">
                <div className="picovinky-veci">
                    <h3>Revitalizace centra obce Libeň</h3>
                    <ul><li>Oprava dešťové kanalizace</li>
<li>Terénní úpravy</li>
<li>Rekonstrukce chodníků a komunikací</li>
<li>Autobusové zastávky</li>
<li>Sadové úpravy</li>
<li>Dopravní značení</li>
<li>Veřejné osvětlení</li>
<li>Městský mobiliář</li>
<li>Revitalizace břehů rybníku</li></ul>
                </div>
                <ImageGallery showPlayButton={false} showFullscreenButton={false} showBullets={true} items={images5} />
            </div>
        </section>
        <section id="strankovani">
            <div class="center">
                <div class="pagination-relas">
                    <Link className="active">1</Link>
                    <Link to="../reference/inzenyrske-stavby-2">2</Link>
                    <Link to="../reference/inzenyrske-stavby-3">3</Link>
                    <Link to="../reference/inzenyrske-stavby-4">4</Link>
                    <Link to="../reference/inzenyrske-stavby-5">5</Link>
                </div>
            </div>
        </section>
    </div>
}

export default InzenyrskeStavby