import { FaFacebookF } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import "./Footer.css"
const Footer = () => {
    return <div>
        <footer>
            <div className="items">
            <h5>KONTAKTUJTE NÁS</h5>
                <p><strong>EKIS spol. s r.o.</strong></p>
                <p>Náchodská 2421</p>
                <p>193 00 Praha 9 - Horní Počernice</p>
                <a href="tel:+420281040611"><strong>+420 281 040 611</strong></a>
                <a href="mailto:ekis@ekis-as.cz"><strong>ekis@ekis-as.cz</strong></a>
                <div className="ikonky-relas">
                <a href="https://www.facebook.com/profile.php?id=100063520063630" target="_blank" rel="noreferrer"><FaFacebookF className="ikonky-footer"/></a>
                <a href="https://www.instagram.com/ekis_stavby_pro_vas/" target="_blank" rel="noreferrer"><FaInstagram className="ikonky-footer"/></a>
                </div>
                <div className="malej-text">
                 <p className="ekis">&copy; EKIS 2023</p>
                 <p className="informace">EKIS spol. s r.o., IČ: 18626084, DIČ: CZ18626084, Náchodská 2421, 193 00 Praha 9 - Horní Počernice, ČR</p>
                 </div>
                 </div>
        </footer>
    </div>
}

export default Footer