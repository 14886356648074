import { Helmet } from "react-helmet";
import questions from "../data"
import "./Poradenstvi.css"
import Question from "../components/Question"
const Poradenstvi = () => {

    return <div>
        <Helmet>
<title>Poradenství</title>
<meta name="title" content="EKIS - Poradenství"/>
<meta name="description" content=""/>
<meta property="og:type" content="website"/>
<meta property="og:url" content="http://ekis-as.cz/poradenstvi"/>
<meta property="og:title" content="EKIS - Poradenství"/>
<meta property="og:description" content=""/>
<meta property="og:image" content=""/>
        </Helmet>
        <section id="poradenstvi">
        <h2 className="h2-faq">Poradenství - FAQ</h2>
            <div className="faq">
            {
                questions.map((oneQuestion) => {
                    return <Question key={oneQuestion.id} {...oneQuestion}/>
      })
    }

            </div>
        </section>
    </div>
}

export default Poradenstvi